import { ServiceModalsProps } from "./types";
import { FC, useMemo } from "react";
import { InteractionType } from "../../../../../../domain/service";
import { LateCheckoutPickerDialog } from "./late-checkout-picker-dialog";
import { ServiceDatesPickerDialog } from "./service-dates-picker-dialog";
import { EarlyCheckinPickerDialog } from "./early-checkin-picker-dialog";
import { CounterPickerDialog } from "./counter-picker-dialog";
import { calculateInteractionType } from "../../../../../../utils/services";
import { InputDialog } from "./input-dialog";

export const ServiceModalsHandler: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const interactionType = useMemo(() => calculateInteractionType(service), [service]);

  if (!service) {
    return <></>;
  }

  switch (interactionType) {
    case InteractionType.LATE_CHECKOUT_DIALOG:
      return (
        <LateCheckoutPickerDialog
          service={service}
          reservation={reservation}
          onClose={onClose}
          onChange={onChange}
        />
      );
    case InteractionType.EARLY_CHECKIN_DIALOG:
      return (
        <EarlyCheckinPickerDialog
          service={service}
          reservation={reservation}
          onClose={onClose}
          onChange={onChange}
        />
      );
    case InteractionType.DATE_PICKER_DIALOG:
      return (
        <ServiceDatesPickerDialog
          service={service}
          reservation={reservation}
          onClose={onClose}
          onChange={onChange}
        />
      );
    case InteractionType.COUNTER:
      return (
        <CounterPickerDialog
          service={service}
          reservation={reservation}
          onClose={onClose}
          onChange={onChange}
        />
      );
    case InteractionType.INPUT:
      return (
        <InputDialog
          service={service}
          reservation={reservation}
          onClose={onClose}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
};
