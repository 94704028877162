import { Grid2, Paper } from "@mui/material";
import { FC } from "react";
import { GuestType } from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { PersonCardHeader } from "./person-card-header";
import { PersonCardInfo } from "./person-card-info";

interface PersonCardProps {
  propertyId: string;
  reservation: ReservationDetailsDTO;
}

const PersonCard: FC<PersonCardProps> = ({ reservation, propertyId }) => {
  return (
    <Grid2 container spacing={2}>
      <Grid2>
        <Paper elevation={0}>
          <Grid2 container>
            <Grid2 container sx={{ width: "100%" }}>
              <PersonCardHeader
                guest={reservation.primaryGuest}
                role={
                  reservation.primaryGuestSameAsBooker
                    ? GuestType.SAME_GUEST
                    : GuestType.PRIMARY_GUEST
                }
              />
              <PersonCardInfo
                guest={reservation.primaryGuest}
                role={
                  reservation.primaryGuestSameAsBooker
                    ? GuestType.SAME_GUEST
                    : GuestType.PRIMARY_GUEST
                }
                propertyId={propertyId}
                reservation={reservation}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2>
        {reservation.booker && !reservation.primaryGuestSameAsBooker && (
          <Paper elevation={0}>
            <Grid2 container>
              <Grid2 container sx={{ width: "100%" }}>
                <PersonCardHeader guest={reservation.booker} role={GuestType.BOOKER} />
                <PersonCardInfo
                  guest={reservation.booker}
                  role={GuestType.BOOKER}
                  propertyId={propertyId}
                  reservation={reservation}
                />
              </Grid2>
            </Grid2>
          </Paper>
        )}
      </Grid2>
      <Grid2 width="100%">
        {reservation.travelBuddies &&
          reservation.travelBuddies.map((travelBuddy) => (
            <Paper elevation={0} sx={{ marginBottom: 3 }}>
              <Grid2 container>
                <PersonCardHeader guest={travelBuddy} role={GuestType.TRAVEL_BUDDY} />
                <PersonCardInfo
                  guest={travelBuddy}
                  role={GuestType.TRAVEL_BUDDY}
                  propertyId={propertyId}
                  reservation={reservation}
                />
              </Grid2>
            </Paper>
          ))}
      </Grid2>
    </Grid2>
  );
};

export default PersonCard;
