import { Badge, Box, Collapse, IconButton, Tooltip, useTheme } from "@mui/material";
import { FC, ReactElement, SyntheticEvent, useCallback, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { resolvePath } from "react-router";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useNavigateWithPropertyId } from "src/hooks/use-navigate-with-propertyId";
import { selectIsOpenSideNavigation, setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useDispatch, useSelector } from "src/store";
import { makeStyles } from "tss-react/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, SUPERUSER_ROLE, useAuth } from "@likemagic-tech/sv-magic-library";
import { CollapseOptionsInterface } from "./options";

interface SidebarItemProps {
  title: string;
  path?: string;
  params?: URLSearchParams;
  icon: ReactElement;
  isCollapsable: boolean;
  collapseOptions?: CollapseOptionsInterface;
  badgeNumber?: number;
  onClick?: () => void;
  iconOnly?: boolean;
}

const useStyle = makeStyles<{ openSideNavigation: boolean }>()((theme, openSideNavigation) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 14,
    borderRadius: 4,
    justifyContent: openSideNavigation ? "flex-start" : "center",
    height: 28,
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.primary.main,
      top: 0,
      left: 0,
      width: 20,
      height: 18
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.grey[600],
      width: 20,
      height: 20,
      fontSize: 20
    },
    "&.MuiButton-startIcon ": {
      marginRight: 0
    },
    "&.MuiButton-primary": {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white
      }
    },
    "&.MuiIconButton-colorPrimary ": {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white
      }
    }
  }
}));

const externalUrlRegEx = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

export const SidebarItem: FC<SidebarItemProps> = ({
  title,
  path,
  params,
  icon,
  isCollapsable,
  collapseOptions,
  badgeNumber,
  onClick,
  iconOnly
}) => {
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const navigate = useNavigateWithPropertyId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(isMobile);
  const { pathname } = useLocation();
  const { classes: selectClasses } = useStyle({
    openSideNavigation: openSideNavigation
  });
  const { spacing, palette } = useTheme();
  const { hasRole, hasSuperuserRole } = useAuth();
  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (path: string | undefined, params?: URLSearchParams) => {
      if (path) {
        const isExternalLink = externalUrlRegEx.test(path);
        if (isExternalLink) {
          window.open(path, "_blank");
        } else {
          navigate(path, params);
          if (isMobile) {
            setOpenSideNavigation(false);
          }
        }
      } else {
        onClick && onClick();
      }
    },
    [navigate, isMobile, setOpenSideNavigation, onClick]
  );

  const exactMatch = (itemPath: string | undefined) =>
    itemPath
      ? !!matchPath(
          {
            path: resolvePath(itemPath).pathname,
            end: true
          },
          pathname
        )
      : false;

  const itemReservationStopPropagation = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const iconExpandLogic = useCallback(
    (visible: boolean) => {
      if (visible) {
        return isOpen ? (
          <ExpandLessIcon onClick={itemReservationStopPropagation} />
        ) : (
          <ExpandMoreIcon onClick={itemReservationStopPropagation} />
        );
      }
    },
    [isOpen, itemReservationStopPropagation]
  );

  return (
    <>
      {openSideNavigation && !iconOnly ? (
        <Button
          key={title}
          fullWidth
          variant={exactMatch(path) ? "primary" : "ghost"}
          onClick={() => {
            handleClick(path, params);
            if (isCollapsable) {
              setIsOpen(!isOpen);
            }
          }}
          sx={{
            py: spacing(0.5),
            px: spacing(1.5),
            borderRadius: spacing(0.5)
          }}
          classes={selectClasses}
          startIcon={icon}
          endIcon={
            !!badgeNumber ? (
              <Badge badgeContent={badgeNumber} sx={{ alignSelf: "center" }} />
            ) : isCollapsable && openSideNavigation ? (
              iconExpandLogic(true)
            ) : undefined
          }
        >
          {openSideNavigation && title}
        </Button>
      ) : (
        <Tooltip key={title} title={title} placement="right" arrow>
          <Box
            sx={{
              width: "max-content"
            }}
          >
            <Badge
              badgeContent={badgeNumber}
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  width: 8,
                  height: 8,
                  right: 7,
                  display: badgeNumber ? "block" : "none",
                  backgroundColor: palette.grey[800]
                }
              }}
            >
              <IconButton
                color={exactMatch(path) ? "primary" : "default"}
                onClick={() => {
                  handleClick(path, params);
                  if (isCollapsable) {
                    setIsOpen(!isOpen);
                  }
                }}
                sx={{
                  borderRadius: spacing(0.5),
                  py: spacing(0.5),
                  px: spacing(1)
                }}
                classes={selectClasses}
              >
                {icon}
              </IconButton>
            </Badge>
          </Box>
        </Tooltip>
      )}

      {isCollapsable && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          <Box>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: spacing(0.75),
                borderLeft: `1px solid ${palette.grey[300]}`,
                ml: openSideNavigation ? spacing(2) : 0,
                pl: openSideNavigation ? spacing(1) : 0
              }}
            >
              {collapseOptions?.items
                ?.filter(
                  (items) =>
                    items?.roles?.some(
                      (role) =>
                        hasRole(role) ||
                        (items.roles.includes(SUPERUSER_ROLE) ? hasSuperuserRole() : false)
                    )
                )
                .map((item) => (
                  <>
                    {openSideNavigation ? (
                      <Button
                        key={item.title}
                        fullWidth
                        variant={exactMatch(item.path) ? "primary" : "ghost"}
                        onClick={() => {
                          handleClick(item.path, item.params);
                        }}
                        sx={{
                          py: spacing(0.5),
                          px: spacing(1.5),
                          borderRadius: spacing(0.5)
                        }}
                        classes={selectClasses}
                        startIcon={item.icon}
                      >
                        {openSideNavigation && item.title}
                      </Button>
                    ) : (
                      <Tooltip
                        key={item.title}
                        title={item.title}
                        disableHoverListener={openSideNavigation}
                        placement="right"
                        sx={{
                          justifyContent: "center"
                        }}
                        arrow
                      >
                        <Box
                          sx={{
                            width: "max-content"
                          }}
                        >
                          <IconButton
                            color={exactMatch(item.path) ? "primary" : "default"}
                            onClick={() => {
                              handleClick(item.path, item.params);
                            }}
                            sx={{
                              borderRadius: spacing(0.5),
                              py: spacing(0.5),
                              px: spacing(1)
                            }}
                            classes={selectClasses}
                          >
                            {item.icon}
                          </IconButton>
                        </Box>
                      </Tooltip>
                    )}
                  </>
                ))}
            </Box>
          </Box>
        </Collapse>
      )}
    </>
  );
};
