import { Box, Grid2, TableRow } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { Chip, formatDate, formatTime, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { useProperty } from "src/hooks/use-property";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useUnits } from "src/hooks/use-unit-by-id";
import { Task, TaskPriority, TaskStatus } from "./domain/task";
import { useUpdateTaskMutationEnhanced } from "../../graphql-tasks/mutations/enhanced-mutations/update-tasks-enhanced";
import { openBanner } from "../../slices/banner.slice";
import { useDispatch } from "../../store";
import { TaskLinks } from "./components/task-links";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { PriorityHigh, SubdirectoryArrowRight } from "@mui/icons-material";
import { isBefore } from "date-fns";
import { StatusCell } from "./components/status-cell";
import { getI18nSelectedLanguage } from "src/utils/language";

type FindUnitFunction = (unitId: string) => {
  value: string;
  id: string;
  label: string;
};

interface TableRowProps {
  data: Task;
  onSelect: (id: string) => void;
  selectedReservationId?: string;
}

export const colorSchemePriority = (priority: TaskPriority | undefined | null) => {
  switch (priority) {
    case TaskPriority.High:
      return "error";
    case TaskPriority.Medium:
      return "warning";
    case TaskPriority.Low:
      return "success";
    default:
      return "default";
  }
};

export const TaskRow: FC<TableRowProps> = ({ data, onSelect }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const [updateTaskAction, result] = useUpdateTaskMutationEnhanced();
  const { data: units } = useUnits({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.data?.UpdateTask?.id) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }
  }, [dispatch, result, t]);

  const unitOptions = useMemo(
    () =>
      units?.map((unit) => ({
        value: unit.id,
        id: unit.id,
        label: t("labels__unit") + " " + unit.name,
        typeId: unit.id,
        type: "UNIT"
      })) || [],
    [units, t]
  );

  const findUnit: FindUnitFunction = useCallback(
    (unitId: string) =>
      unitOptions.find((opt) => opt?.typeId === unitId) ?? {
        value: "",
        id: "",
        label: ""
      },
    [unitOptions]
  );

  const priorityChipDisplay = (priority: TaskPriority) => {
    const color = colorSchemePriority(priority);
    const accentChipProps = {
      backgroundColor: palette.accent.light,
      color: palette.accent.dark,
      "& .MuiChip-icon": {
        color: palette.accent.dark
      }
    };

    return (
      <Chip
        size="small"
        leftIcon={<EmojiFlagsIcon />}
        sx={priority === TaskPriority.Urgent ? accentChipProps : {}}
        color={color}
        label={t(`labels__additional_filter_${priority}`)}
      />
    );
  };

  const onStatusChange = useCallback(
    (newStatus: TaskStatus) => {
      const filteredValues = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null && v !== "")
      ) as Task;
      const {
        id,
        attachments,
        updatedAt,
        assignee,
        assigneeGroup,
        subtasks,
        createdAt,
        reporter,
        type,
        metadata,
        pmsId,
        ...restOfValues
      } = filteredValues;

      updateTaskAction({
        pmsPropertyId: selectedProperty?.propertyId ?? "",
        request: {
          ...restOfValues,
          links: {
            pmsUnitIds: restOfValues.links?.pmsUnitIds,
            pmsReservationIds: restOfValues.links?.pmsReservationIds,
            pmsDepartingReservationId: restOfValues.links?.pmsDepartingReservationId,
            pmsArrivingReservationId: restOfValues.links?.pmsArrivingReservationId
          },
          assigneeId: parseInt(assignee?.actorId ?? "") ?? 0,
          assigneeGroupId: parseInt(assigneeGroup?.actorId ?? "") ?? 0,
          attachments: data.attachments?.map((item) => item.uuid),
          status: newStatus
        },
        taskId: data.id
      }).unwrap();
    },
    [updateTaskAction, data, selectedProperty?.propertyId]
  );

  const dueDateWarning = useMemo(() => {
    return data.due && isBefore(new Date(data.due), new Date()) && data.status !== TaskStatus.Done;
  }, [data.due, data.status]);

  const dueColor = useMemo(() => {
    return dueDateWarning ? palette.error.main : palette.text.primary;
  }, [dueDateWarning, palette.error.main, palette.text.primary]);

  return (
    <TableRow
      hover
      key={data.id}
      onClick={(e) => {
        // Stopping propagation here so MUI handles resetvation actions menu closures
        // And only allowing onClick row when we are not clicking on the resetvation actions button
        const element = e?.target as Element;
        const parentElement = element?.parentElement as Element;
        const elementIsPath = element?.nodeName === "path";
        const shouldStopPropagation = elementIsPath
          ? !parentElement?.getAttribute("data-stop-propagation")
          : !element?.getAttribute("data-stop-propagation");
        if (shouldStopPropagation) {
          onSelect(data.id.toString());
        }
      }}
      sx={{ cursor: "pointer" }}
    >
      {!isMobile && <TableCell />}
      <TableCell>
        <Grid2 container direction="column">
          <Grid2
            sx={{
              display: "flex",
              gap: 0.5
            }}
          >
            <ParagraphSmall sx={{ color: palette.text.primary }}>{data.title}</ParagraphSmall>
            <ParagraphSmall sx={{ color: palette.text.secondary }}>
              ({t("labels__task").toUpperCase()}-{data.id})
            </ParagraphSmall>
          </Grid2>
          {!!data?.subtasks?.length && (
            <Grid2>
              {isMobile && (
                <ParagraphSmall
                  mt={0.5}
                  sx={{
                    color: palette.text.secondary,
                    display: "flex",
                    gap: 0.5
                  }}
                >
                  {findUnit(data?.links?.pmsUnitIds?.[0] ?? "")?.label}
                </ParagraphSmall>
              )}
              <ParagraphSmall
                mt={0.5}
                sx={{
                  color: palette.text.secondary,
                  display: "flex",
                  gap: 0.5
                }}
              >
                <SubdirectoryArrowRight
                  style={{
                    marginLeft: 0.5,
                    marginTop: 1,
                    fontSize: "14px",
                    color: palette.text.secondary
                  }}
                />
                {data?.subtasks?.length === 1 && `1 ${t("labels__subtask")}`}
                {data?.subtasks?.length > 1 && `${data?.subtasks?.length} ${t("labels__subtasks")}`}
              </ParagraphSmall>
            </Grid2>
          )}
        </Grid2>
      </TableCell>
      <TableCell data-stop-propagation>
        <Grid2
          container
          sx={{
            pt: 0.75,
            pb: 0.625
          }}
        >
          <StatusCell data-stop-propagation status={data.status} onStatusChange={onStatusChange} />
        </Grid2>
      </TableCell>
      <TableCell>
        <ParagraphSmall>{data?.assignee?.displayName}</ParagraphSmall>
        <ParagraphSmall>{data?.assigneeGroup?.displayName}</ParagraphSmall>
      </TableCell>
      <TableCell>
        <TaskLinks task={data} units={units} />
      </TableCell>
      <TableCell>
        {data.due && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <ParagraphSmall sx={{ color: dueColor, display: "flex", alignItems: "center" }}>
              {dueDateWarning && (
                <PriorityHigh
                  data-stop-propagation
                  sx={{ color: palette.error.main, fontSize: 16 }}
                />
              )}
              {formatDate(data.due, language, selectedProperty?.details.timeZone)}
            </ParagraphSmall>
            <ParagraphSmall
              sx={{ color: dueDateWarning ? palette.error.main : palette.text.secondary }}
            >
              {formatTime(data.due, language, selectedProperty?.details.timeZone)}
            </ParagraphSmall>
          </Box>
        )}
      </TableCell>
      <TableCell>
        {data.createdAt && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <ParagraphSmall
              sx={{ color: palette.text.primary, display: "flex", alignItems: "center" }}
            >
              {formatDate(data.createdAt, language, selectedProperty?.details.timeZone)}
            </ParagraphSmall>
            <ParagraphSmall sx={{ color: palette.text.secondary }}>
              {formatTime(data.createdAt, language, selectedProperty?.details.timeZone)}
            </ParagraphSmall>
          </Box>
        )}
      </TableCell>
      <TableCell>{priorityChipDisplay(data.priority)}</TableCell>
      {!isMobile && <TableCell />}
      <TableCell />
    </TableRow>
  );
};
