import { Input } from "@likemagic-tech/sv-magic-library";
import { Grid2 } from "@mui/material";
import { FC } from "react";
import { GuestType } from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { getI18nSelectedLanguage, getTranslatedLanguageName } from "src/utils/language";
import { twoLetterLanguageCode } from "src/components/language-selector/language-selector";
import { Person } from "src/domain/person";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { regionNames } from "./reservation-details-guest-insight-utils";

interface PersonCardInfoPersonalInfoProps {
  guest: Person;
  role: GuestType;
  showField: boolean;
}

export const PersonCardInfoPersonalInfo: FC<PersonCardInfoPersonalInfoProps> = ({
  guest,
  role
}) => {
  const { t } = useTranslationWrapper();
  const language = getI18nSelectedLanguage();
  return (
    <Grid2 container direction="row" spacing={2} px={3}>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={guest.email}
          label={t("labels__email")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={guest.phone}
          label={t("labels__phone_number")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={
            guest.preferredCommunicationChannel
              ? t(`labels__guest__communication__channel__${guest.preferredCommunicationChannel}`)
              : ""
          }
          label={t("labels__communication_channel")}
        />
      </Grid2>
      {role !== GuestType.TRAVEL_BUDDY && (
        <Grid2 size={{ xs: 6, md: 4 }}>
          <Input
            disabled
            variant="outlined"
            forceShrink
            value={
              guest.preferredLanguage
                ? getTranslatedLanguageName({
                    code: twoLetterLanguageCode(guest.preferredLanguage),
                    locale: language
                  })
                : ""
            }
            label={t("labels__reservation__details__overview__guests__language")}
          />
        </Grid2>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <Grid2 size={{ xs: 6, md: 4 }}>
          <Input
            disabled
            variant="outlined"
            forceShrink
            value={
              guest.address?.addressLine1
                ? `${guest.address?.addressLine1} ${guest.address?.addressLine2 ?? ""}`
                : ""
            }
            label={t("labels__guest__insight__PRIVATE_ADDRESS")}
          />
        </Grid2>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <Grid2 size={{ xs: 6, md: 4 }}>
          <Input
            disabled
            variant="outlined"
            forceShrink
            value={guest.address?.postalCode}
            label={t("labels__postal_code")}
          />
        </Grid2>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <Grid2 size={{ xs: 6, md: 4 }}>
          <Input
            disabled
            variant="outlined"
            forceShrink
            value={guest.address?.countryCode ? regionNames.of(guest.address?.countryCode) : ""}
            label={t("labels__country")}
          />
        </Grid2>
      )}
    </Grid2>
  );
};
