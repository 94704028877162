import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { useCallback, useContext, useMemo } from "react";
import { useProperty } from "./use-property";
import { useUserInfo } from "./use-user-info";

export const useCreateSupportTicket = () => {
  const { urls, tenantId } = useContext(TenantContext);
  const { selectedProperty } = useProperty();
  const { userInfo } = useUserInfo();

  const isSchaniEnabled = useMemo(() => {
    return (
      ("schani" === tenantId && userInfo?.email === "vk.weikartschlaeger@schanihotels.com") ||
      "schani" !== tenantId
    );
  }, [tenantId, userInfo?.email]);

  const createSupportTicket = useCallback(() => {
    window.open(
      urls.magicSupportUrl.replace("{{property}}", selectedProperty?.details?.name ?? ""),
      "_blank"
    );
  }, [urls, selectedProperty?.details?.name]);

  const supportExists = useMemo(
    () => (!!urls.magicSupportUrl || !!selectedProperty?.details?.name) && isSchaniEnabled,
    [urls, selectedProperty?.details?.name, isSchaniEnabled]
  );

  return { createSupportTicket, supportExists };
};
