import * as Types from "../generated/graphql";

export type TaskConfigFragment = {
  __typename?: "TaskConfig";
  housekeepingMainTask?: boolean | null;
  assigneeGroup?: string | null;
  createMultipleTasks?: boolean | null;
  dueDuration?: string | null;
  dueDurationType?: Types.DueDurationType | null;
  granularity?: Types.TaskGranularity | null;
  priority?: Types.Priority | null;
};

export const TaskConfigFragmentDoc = `
    fragment TaskConfig on TaskConfig {
  housekeepingMainTask
  assigneeGroup
  createMultipleTasks
  dueDuration
  dueDurationType
  granularity
  priority
}
    `;
