import { FC, useCallback, useMemo, useState } from "react";
import { Box, Grid2, Tab, useTheme } from "@mui/material";
import { ReloadButton } from "../../components/reload-button";
import { useProperty } from "../../hooks/use-property";
import { UnitCard } from "../unit/unit-card";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Forecast } from "./forecast";
import { add } from "date-fns";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useUnitsWithAvailableDoors } from "src/hooks/use-units-with-available-doors";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { UnitDashboardDTO } from "../../domain/UnitDashboardDTO";
import { useFetchForecast } from "./use-fetch-forecast";
import { MaintenanceModal } from "src/components/maintenance-modal/maintenance-modal";
import { DirtyUnitsActions } from "./dirty-units-actions";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { grey } from "@mui/material/colors";
import { useUnitBulkActions } from "../../hooks/use-unit-bulk-actions";

enum TAB_VALUES {
  TODAY = "1",
  FORECAST = "2"
}

const NUMBER_OF_DAYS_FORECAST = 14;

export const DirtyUnitsPage: FC = () => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const { bulkAction, addMaintenance, setMaintenanceModalOpen, maintenanceModalOpen } =
    useUnitBulkActions();
  const { data: unitsToBeCleaned, fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const [tabValue, setTabValue] = useState(TAB_VALUES.TODAY);

  const handleTabChange = (_: TAB_VALUES, newValue: TAB_VALUES) => {
    setTabValue(newValue);
  };

  const startDate = useMemo(() => new Date(), []);

  const endDate = useMemo(
    () => add(new Date(startDate), { days: NUMBER_OF_DAYS_FORECAST }),
    [startDate]
  );

  const { fetchForecast, departuresAndCleaningsForecast } = useFetchForecast({
    propertyId: selectedProperty?.propertyId,
    startDate,
    endDate
  });

  const refreshUnits = useCallback(() => {
    if (!selectedProperty?.propertyId) {
      return;
    }

    return fetchAction();
  }, [fetchAction, selectedProperty?.propertyId]);

  const units = useUnitsWithAvailableDoors(selectedProperty?.propertyId ?? "");

  const unitsToBeDisplayed: UnitDashboardDTO[] = useMemo(
    () =>
      unitsToBeCleaned.map((unit) => {
        const unitDoorData = units?.find((item) => item.id === unit.id);

        return {
          ...unit,
          privacyMode: (unit?.privacyMode || unitDoorData?.privacyMode) ?? false,
          privacyModeLastChangeTimestamp:
            unit?.privacyModeLastChangeTimestamp ??
            unitDoorData?.privacyModeLastChangeTimestamp ??
            "",
          door: unitDoorData?.door
        };
      }),

    [unitsToBeCleaned, units]
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: "100%"
        }}
      >
        <Grid2 container spacing={3}>
          <Grid2
            container
            spacing={3}
            size={{ xs: 12 }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("labels__housekeeping")} />
            <Grid2
              sx={{
                gap: 1,
                display: "flex",
                alignItems: "center"
              }}
            >
              {tabValue !== TAB_VALUES.FORECAST && <DirtyUnitsActions onClick={bulkAction} />}
              <ReloadButton
                onClick={() => {
                  fetchAction();
                  fetchForecast();
                }}
              />
            </Grid2>
          </Grid2>
          <TabContext value={tabValue}>
            <Grid2>
              <TabList
                onChange={(event, newValue) =>
                  handleTabChange(event as unknown as TAB_VALUES, newValue)
                }
              >
                <Tab
                  sx={{
                    textTransform: "none",
                    color: grey[600],
                    fontWeight: "bold"
                  }}
                  value={TAB_VALUES.TODAY}
                  label={t("labels__today")}
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    color: grey[600],
                    fontWeight: "bold"
                  }}
                  value={TAB_VALUES.FORECAST}
                  label={t("labels__forecast")}
                />
              </TabList>
            </Grid2>

            <TabPanel value={TAB_VALUES.TODAY} sx={{ width: "100%", p: 0 }}>
              <Grid2
                container
                size={{ xl: 12 }}
                spacing={2}
                sx={{
                  pb: 3
                }}
              >
                {unitsToBeDisplayed?.map((unit) => (
                  <UnitCard
                    key={unit.id}
                    unit={unit}
                    propertyId={selectedProperty?.propertyId ?? ""}
                    refreshUnitList={refreshUnits}
                  />
                ))}
              </Grid2>
            </TabPanel>
            <TabPanel value={TAB_VALUES.FORECAST} sx={{ width: "100%", p: 0 }}>
              <Forecast
                startDate={startDate}
                endDate={endDate}
                departuresAndCleaningsForecast={departuresAndCleaningsForecast}
              />
            </TabPanel>
          </TabContext>
        </Grid2>
      </Box>
      {maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => addMaintenance(values)}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
