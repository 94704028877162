import { Checkbox, Input } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import { FC, Fragment } from "react";
import { Person } from "src/domain/person";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface PersonCardInfoOtherInfoProps {
  guest: Person;
  showTfeFields: boolean;
}

export const PersonCardInfoOtherInfo: FC<PersonCardInfoOtherInfoProps> = ({
  guest,
  showTfeFields
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  return (
    <Grid2 container direction="row" spacing={2} px={2} alignItems="center">
      {showTfeFields &&
        guest.frequentFlyerPrograms?.map((program) => (
          <Fragment key={program.membershipId}>
            <Grid2 size={{ xs: 6 }}>
              <Input
                disabled
                variant="outlined"
                value={program.membershipTypeCode}
                label={t("labels__frequent_flyer_program")}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Input
                disabled
                variant="outlined"
                value={program.membershipId}
                label={t("labels__membership_number")}
              />
            </Grid2>
          </Fragment>
        ))}
      <Grid2 size={{ xs: 6 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={guest.licensePlate}
          label={t("labels__license_plate")}
        />
      </Grid2>
      {showTfeFields && (
        <Grid2 size={{ xs: 6 }} sx={{ marginTop: theme.spacing(-2.5) }}>
          <Checkbox
            id={"enrolledInLoyaltyProgram"}
            checked={!!guest.enrolledInLoyaltyProgram}
            onChange={() => {}}
            disabled
            title={t("labels__enrolled_loyalty_program")}
          />
        </Grid2>
      )}
      {showTfeFields && (
        <Grid2 size={{ xs: 6 }}>
          <Checkbox
            checked={guest.emergencyEvacuationHelpNeeded ?? false}
            onChange={() => {}}
            disabled
            title={t("labels__special_assistance")}
            text={
              guest.emergencyEvacuationHelpNotes
                ? `${t("labels__guest_input")} ${guest.emergencyEvacuationHelpNotes}`
                : t("labels__require_special_assistance")
            }
            sx={{ bottom: theme.spacing(1) }}
          />
        </Grid2>
      )}
    </Grid2>
  );
};
