import {
  DateAndCount,
  DefaultDatePickerSelection,
  DefaultTargetAudience,
  Granularity,
  Visibility
} from "./service";
import { Price } from "./price";

export interface AdditionalServicesAvailabilityResponse {
  available?: boolean;
  serviceId: string;
  code: string; //TODO To be implemented on BE
  name: string;
  granularity?: Granularity;
  bookableDates?: DateAndCount[];
  bookedDates?: DateAndCount[];
  price?: Price;
  defaultTargetAudience?: DefaultTargetAudience | null;
  defaultDatePickerSelection?: DefaultDatePickerSelection | null;
  canBookMultiple?: boolean;
  maximum?: number;
  // late checkout/early checkin only
  maximumHours: number;
  visibilities: Visibility[];
  serviceType?: ServiceConfigType | null;
  addOnType?: AddOnType | null;
}

export enum ServiceConfigType {
  AdditionalCleaning = "ADDITIONAL_CLEANING",
  AddOn = "ADD_ON",
  EarlyCheckin = "EARLY_CHECKIN",
  FreeCleaning = "FREE_CLEANING",
  LateCheckout = "LATE_CHECKOUT"
}

export enum AddOnType {
  Other = "OTHER",
  Parking = "PARKING"
}
