import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customErrors, prepareHeaders } from "../utils/graphql.api";

export const GUEST_SERVICE_BASE_URL = "/api/guest-service/graphql";

export const GraphqlClient = new GraphQLClient(GUEST_SERVICE_BASE_URL);

export const graphqlApi = createApi({
  reducerPath: "graphql",
  baseQuery: graphqlRequestBaseQuery({
    client: GraphqlClient,
    prepareHeaders,
    customErrors
  }),
  tagTypes: [
    "UserProfile",
    "UserProfileNotes",
    "Units",
    "AdditionalServicesAvailability",
    "ReservationDetails",
    "ReservationsOverview",
    "UpsellReservation"
  ],
  endpoints: () => ({})
});
