import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BackofficeUpdatePrimaryGuestMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  person: Types.UpdatePersonInput;
}>;

export type BackofficeUpdatePrimaryGuestMutation = {
  __typename?: "Mutation";
  BackofficeUpdatePrimaryGuest: { __typename?: "Person"; id: number };
};

export const BackofficeUpdatePrimaryGuestDocument = `
    mutation BackofficeUpdatePrimaryGuest($pmsReservationId: String!, $person: UpdatePersonInput!) {
  BackofficeUpdatePrimaryGuest(
    pmsReservationId: $pmsReservationId
    person: $person
  ) {
    id
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BackofficeUpdatePrimaryGuest: build.mutation<
      BackofficeUpdatePrimaryGuestMutation,
      BackofficeUpdatePrimaryGuestMutationVariables
    >({
      query: (variables) => ({ document: BackofficeUpdatePrimaryGuestDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBackofficeUpdatePrimaryGuestMutation } = injectedRtkApi;
