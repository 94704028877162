import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customErrors, prepareHeaders } from "../utils/graphql.api";

export const MESSAGE_BASE_URL = "/api/unified-messaging/graphql";
export const MessagingGraphqlClient = new GraphQLClient(MESSAGE_BASE_URL);

export const messagingApi = createApi({
  reducerPath: "graphql-messaging",
  baseQuery: graphqlRequestBaseQuery({
    client: MessagingGraphqlClient,
    prepareHeaders,
    customErrors
  }),
  tagTypes: ["ConversationDetails"],
  endpoints: () => ({})
});
