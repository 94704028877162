import { Grid2 } from "@mui/material";
import { FC } from "react";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import PersonCard from "./person-card";

interface ReservationDetailsGuestInsightProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsGuestInsight: FC<ReservationDetailsGuestInsightProps> = ({
  reservation
}) => {
  return (
    <Grid2
      container
      sx={{
        pt: 2
      }}
    >
      <Grid2 size={{ xs: 12 }}>
        <PersonCard reservation={reservation} propertyId={reservation?.propertyId} />
      </Grid2>
    </Grid2>
  );
};
