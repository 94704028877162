import { ProfileDrawerCards } from "./profile-drawer-cards/profile-drawer-cards";
import { Grid2, Paper, Table, TableBody } from "@mui/material";
import { EnhancedTableHead } from "src/components/table/enhanced-table-head";
import { ConversationRow } from "src/messaging/components/conversation-row";
import { useConversationsHeadCells } from "src/messaging/hooks/use-conversations-head-cells";
import { Conversation } from "src/messaging/domain/conversation";
import { ChangeEvent, FC, MouseEvent, useMemo, useState } from "react";
import { Order, OrderEnum } from "src/types/pageable";
import { useGetConversationsQuery } from "src/graphql-messaging/queries/GetConversations.generated";
import { useProperty } from "src/hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { EmptyState, EmptyStateSize, Heading4 } from "@likemagic-tech/sv-magic-library";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { useConversationSelectId } from "../../../messaging/use-conversation-select-id";

interface ProfileDrawerMessagesProps {
  guestIdentityId?: number | null;
}

export const ProfileDrawerMessages: FC<ProfileDrawerMessagesProps> = ({ guestIdentityId }) => {
  const { selectedConversationId, openDrawer } = useConversationSelectId();

  const { t } = useTranslationWrapper();
  const { selectedPropertyIds } = useProperty();
  const conversationsHeadCells = useConversationsHeadCells();
  const [orderConversations, setOrderConversations] = useState<Order>(OrderEnum.DESC);
  const [orderByConversations, setOrderByConversations] =
    useState<keyof Conversation>("lastReceivedMessage");
  const [messagesPage, setMessagesPage] = useState(0);
  const [messagesRowsPerPage, setMessagesRowsPerPage] = useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setMessagesPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setMessagesRowsPerPage(parseInt(event.target.value, 10));
    setMessagesPage(0);
  };

  const onRowSelect = (conversationId: string) => {
    openDrawer(conversationId);
  };

  const pageable = useMemo(
    () => ({
      pageNumber: messagesPage,
      pageSize: messagesRowsPerPage,
      sort: `${orderByConversations},${orderConversations}`
    }),
    [orderByConversations, orderConversations, messagesRowsPerPage, messagesPage]
  );

  const handleConversationRequestSort = (
    event: MouseEvent<unknown>,
    orderByConversations: keyof Conversation
  ) => {
    setOrderConversations(orderConversations === "desc" ? "asc" : "desc");
    setOrderByConversations(orderByConversations);
  };

  const { data: conversations } = useGetConversationsQuery(
    {
      filter: {
        pageable,
        pmsPropertyIds: selectedPropertyIds,
        guestIdentityId: guestIdentityId
      }
    },
    { skip: !selectedPropertyIds?.length || !guestIdentityId }
  );

  const convervationRows = useMemo(
    () => conversations?.GetConversations?.content || [],
    [conversations?.GetConversations]
  );

  const conversationTotalElements = useMemo(
    () => conversations?.GetConversations.totalElements,
    [conversations?.GetConversations]
  );

  return (
    <ProfileDrawerCards>
      {convervationRows.length > 0 ? (
        <Grid2 size={{ xs: 12 }}>
          <Paper elevation={0}>
            <Grid2
              container
              sx={{
                justifyContent: "space-between",
                flexDirection: "column"
              }}
            >
              <Grid2
                sx={{
                  alignItems: "top",
                  display: "flex",
                  p: 3
                }}
              >
                <Heading4>{t("labels__profiles_messages")}</Heading4>
              </Grid2>
              <Grid2 container direction="column">
                <TableContainer>
                  <Table sx={{ textWrap: "nowrap" }}>
                    <EnhancedTableHead<Conversation>
                      headCells={conversationsHeadCells}
                      order={orderConversations}
                      orderBy={orderByConversations}
                      onRequestSort={handleConversationRequestSort}
                    />
                    <TableBody>
                      {convervationRows.map((row: Conversation) => (
                        <ConversationRow
                          key={row.conversationId}
                          data={row}
                          onSelect={onRowSelect}
                          isSelected={selectedConversationId === row.conversationId}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={conversationTotalElements ?? 0}
                  rowsPerPage={messagesRowsPerPage}
                  page={messagesPage}
                  labelRowsPerPage={t("labels__rows_per_page")}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("labels__of")} ${count}`
                  }
                />
              </Grid2>
            </Grid2>
          </Paper>
        </Grid2>
      ) : (
        <EmptyState
          title={t("labels__profile_no_message_information")}
          size={EmptyStateSize.LARGE}
        />
      )}
    </ProfileDrawerCards>
  );
};
