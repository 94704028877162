import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { UpdatePersonInput } from "src/graphql/generated/graphql";
import { useBackofficeUpdatePrimaryGuestMutation } from "src/graphql/mutations/backoffice-update-primary-guest.generated";

interface UpdatePrimaryGuestProps {
  reservationId: string;
  person: UpdatePersonInput;
}

export const useUpdatePrimaryGuest = () => {
  const { isRESTVersion } = useApiVersion();
  const [updatePrimaryGuestMutation] = useBackofficeUpdatePrimaryGuestMutation();

  const updatePrimaryGuestV1 = () => {
    return Promise.reject("not yet implemented");
  };

  const updatePrimaryGuestV2 = ({ reservationId, person }: UpdatePrimaryGuestProps) => {
    return updatePrimaryGuestMutation({
      pmsReservationId: reservationId,
      person: person
    });
  };

  const functionCall = isRESTVersion ? updatePrimaryGuestV1 : updatePrimaryGuestV2;

  return [functionCall] as [typeof updatePrimaryGuestV2];
};
