import { combineReducers } from "@reduxjs/toolkit";
import { reducer as supportedSyncStatesReducer } from "../slices/supported-sync-states";
import { reducer as availableDoorReducer } from "../slices/available-doors";
import { reducer as cleaningReducer } from "../slices/cleaning";
import { reducer as dashboardArrivalReducer } from "../slices/dashboard-arrival";
import { reducer as dashboardDepartureReducer } from "../slices/dashboard-departure";
import { reducer as dashboardInHotelReducer } from "../slices/dashboard-in-hotel";
import { reducer as dashboardBreakfastReducer } from "../slices/dashboard-breakfast";
import { reducer as datePickerReducer } from "../slices/date-picker";
import { reducer as propertiesReducer } from "../slices/properties";
import { reducer as scheduledCleaningReducer } from "../slices/scheduled-cleanings";
import { reducer as departuresAndCleaningsForecastReducer } from "../slices/departures-and-cleanings-forecast";
import { keycloakSlice } from "../slices/keycloak.slice";
import { bannerSlice } from "../slices/banner.slice";
import { loaderSlice } from "../features/loaders/loader.slice";
import { keyAssignSlice } from "../slices/assign-key";
import { magicApi } from "./magic.api";
import { downloadSlice } from "../slices/download.slice";
import { dashboardSidebarSlice } from "src/slices/dashboard-sidebar";
import { magicFiles } from "../slices/magic-files.slice";
import { boxSlice } from "../slices/box.slice";
import { graphqlApi } from "../graphql/graphql.api";
import { availableDoorsStateSlice } from "src/slices/available-doors-state.slice";
import { userInfoSLice } from "../slices/user-info.slice";
import { messagingApi } from "../graphql-messaging/messaging.api";
import { unreadMessageCountSlice } from "../slices/unread-message-count.slice";
import { messagingAttachmentSlice } from "../slices/messaging-attachment.slice";
import { tasksApi } from "../graphql-tasks/tasks.api";
import { taskManagementFilesApi } from "./task-management-files.api";
import { paymentSlice } from "../slices/payment.slice";
import {
  drawerStackSlicePersistConfig,
  paymentSlicePersistConfig,
  recentlyUsedDevicesSlicePersistConfig,
  releaseNotesSlicePersistConfig
} from "../config/redux-persist.config";
import { persistReducer } from "redux-persist";
import { secondScreenSlice } from "../slices/second-screen.slice";
import { guestServiceFilesApi } from "./guest-service-files.api";
import { sendPaymentLinkModalSlice } from "../slices/send-payment-link-modal.slice";
import { recentlyUsedDevicesSlice } from "../slices/recently-used-devices.slice";
import { drawerStackSlice } from "../slices/drawer-stack.slice";
import { tenantconfigApi } from "../graphql-tenantconfig/tenantconfig.api";
import { releaseNotesSlice } from "../slices/release-notes.slice";

const rootReducer = combineReducers({
  dashboardArrival: dashboardArrivalReducer,
  dashboardDeparture: dashboardDepartureReducer,
  dashboardInHotel: dashboardInHotelReducer,
  dashboardBreakfast: dashboardBreakfastReducer,
  cleaning: cleaningReducer,
  datePicker: datePickerReducer,
  property: propertiesReducer,
  scheduledCleaning: scheduledCleaningReducer,
  departuresAndCleaningsForecast: departuresAndCleaningsForecastReducer,
  availableDoor: availableDoorReducer,
  [availableDoorsStateSlice.name]: availableDoorsStateSlice.reducer,
  supportedSyncStates: supportedSyncStatesReducer,
  [keycloakSlice.name]: keycloakSlice.reducer,
  [bannerSlice.name]: bannerSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [keyAssignSlice.name]: keyAssignSlice.reducer,
  [downloadSlice.name]: downloadSlice.reducer,
  [sendPaymentLinkModalSlice.name]: sendPaymentLinkModalSlice.reducer,
  [magicFiles.name]: magicFiles.reducer,
  [dashboardSidebarSlice.name]: dashboardSidebarSlice.reducer,
  [boxSlice.name]: boxSlice.reducer,
  [userInfoSLice.name]: userInfoSLice.reducer,
  [unreadMessageCountSlice.name]: unreadMessageCountSlice.reducer,
  [messagingAttachmentSlice.name]: messagingAttachmentSlice.reducer,
  [paymentSlice.name]: persistReducer(paymentSlicePersistConfig, paymentSlice.reducer),
  [recentlyUsedDevicesSlice.name]: persistReducer(
    recentlyUsedDevicesSlicePersistConfig,
    recentlyUsedDevicesSlice.reducer
  ),
  [drawerStackSlice.name]: persistReducer(drawerStackSlicePersistConfig, drawerStackSlice.reducer),
  [secondScreenSlice.name]: secondScreenSlice.reducer,
  [releaseNotesSlice.name]: persistReducer(
    releaseNotesSlicePersistConfig,
    releaseNotesSlice.reducer
  ),
  /* RTK Query slices start*/
  //V1 slice
  [magicApi.reducerPath]: magicApi.reducer,
  [taskManagementFilesApi.reducerPath]: taskManagementFilesApi.reducer,
  [guestServiceFilesApi.reducerPath]: guestServiceFilesApi.reducer,
  //V2 slices
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  [messagingApi.reducerPath]: messagingApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [tenantconfigApi.reducerPath]: tenantconfigApi.reducer
  /* RTK Query slices end*/
});

export default rootReducer;
