import type { FC, PropsWithChildren } from "react";
import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";
import { LikeMagicLogo } from "./likemagic-logo";
import { TfeLogo } from "./tfe-logo";

export interface LogoProps {
  sx?: SxProps<Theme>;
  isDark?: boolean;
  height?: string;
  collapsed?: boolean;
  isTfe?: boolean;
}

const LOGO_HEIGHT = "64";

const Logo: FC<PropsWithChildren<LogoProps>> = (props) => {
  return props.isTfe ? (
    <TfeLogo {...props} height={props.height || LOGO_HEIGHT} />
  ) : (
    <LikeMagicLogo {...props} height={props.height || LOGO_HEIGHT} />
  );
};

export default Logo;
