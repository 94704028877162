import { useProperty } from "../../../hooks/use-property";
import { useGetServiceConfigsQueryEnhanced } from "../../../graphql-tenantconfig/queries/enhanced-queries/get-service-configs-enhanced";
import { TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useMemo } from "react";
import Table from "@mui/material/Table";
import { ServiceConfig } from "../../../graphql-tenantconfig/generated/graphql";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Order } from "../../../types/pageable";
import TableBody from "@mui/material/TableBody";
import AdditionalServicesTableRow from "./additional-services-table-row";

const COMPARATORS = new Map<
  string,
  (
    a: ServiceConfig,
    b: ServiceConfig,
    t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
  ) => number
>([
  [
    "service.displayName",
    (
      a: ServiceConfig,
      b: ServiceConfig,
      t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
    ) => {
      let aDisplayName = a?.service?.displayName || a?.serviceId || "";
      let bDisplayName = b?.service?.displayName || b?.serviceId || "";
      return aDisplayName.toLowerCase().localeCompare(bDisplayName.toLowerCase());
    }
  ],
  [
    "type",
    (
      a: ServiceConfig,
      b: ServiceConfig,
      t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
    ) => {
      let aType = a?.type ? t("labels__settings_service_config_type_" + a.type) : "";
      let bType = b?.type ? t("labels__settings_service_config_type_" + b.type) : "";
      return aType.toLowerCase().localeCompare(bType.toLowerCase());
    }
  ]
]);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let aValue = (a[orderBy] as string) || "";
  let bValue = (b[orderBy] as string) || "";
  return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
}

function getComparator(
  order: Order,
  orderBy: string,
  t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
): (a: ServiceConfig, b: ServiceConfig) => number {
  if (COMPARATORS.has(orderBy)) {
    return order === "desc"
      ? (a, b) => COMPARATORS.get(orderBy)!(a, b, t)
      : (a, b) => -COMPARATORS.get(orderBy)!(a, b, t);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy as keyof ServiceConfig)
      : (a, b) => -descendingComparator(a, b, orderBy as keyof ServiceConfig);
  }
}

const AdditionalServicesTable = () => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const { selectedProperty } = useProperty();

  const [order, setOrder] = React.useState<Order>();
  const [orderBy, setOrderBy] = React.useState<string>();

  const { data } = useGetServiceConfigsQueryEnhanced(
    { pmsPropertyId: selectedProperty?.propertyId },
    {}
  );

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rows = useMemo(
    () => (data?.GetServiceConfigs ? (data?.GetServiceConfigs as ServiceConfig[]) : []),
    [data?.GetServiceConfigs]
  );

  const sortedRows = useMemo(
    () => (order && orderBy ? [...rows].sort(getComparator(order, orderBy, t)) : rows),
    [rows, order, orderBy, t]
  );

  return (
    <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
      <TableContainer sx={{ paddingTop: spacing(1) }}>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: spacing(6.5) }}>
                <TableSortLabel
                  active={orderBy === "service.displayName"}
                  direction={orderBy === "service.displayName" ? order : "asc"}
                  onClick={createSortHandler("service.displayName")}
                >
                  {t("labels__settings_service_config_name")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "serviceId"}
                  direction={orderBy === "serviceId" ? order : "asc"}
                  onClick={createSortHandler("serviceId")}
                >
                  {t("labels__settings_service_config_pms_id")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_price")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_vat")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_charge_mode")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_charge_unit")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "type"}
                  direction={orderBy === "type" ? order : "asc"}
                  onClick={createSortHandler("type")}
                >
                  {t("labels__settings_service_config_type")}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => (
              <AdditionalServicesTableRow key={`row-${row.serviceConfigHash}`} data={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdditionalServicesTable;
