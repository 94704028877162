import React, { FC, useMemo } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import {
  BaseTableFiltersProps,
  FilterMapInterface,
  FilterObjectType,
  TableFilter
} from "../../components/table/filters/table-filters";
import { QuickFilterCheckbox } from "src/components/quick-filter-checkbox/quick-filter-checkbox";
import Plus from "src/icons/Plus";
import { FilterOperator } from "src/types/filters/filters";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useTaskModal } from "./use-task-select-id";
import {
  SortedTaskPriorities,
  TaskConditionsResponse,
  TaskOwnershipFilters,
  TaskStatus
} from "./domain/task";
import { Button } from "@likemagic-tech/sv-magic-library";
import { AddedFilters } from "../../components/table/filters/added-filters";
import { EmployeeAutocompleteFilters } from "../../components/table/filters/employee-filter/employee-autocomplete-filters";
import { AutocompleteFilterType } from "../../components/table/filters/components/autocomplete-filter";
import { AdditionalFilters } from "../../components/table/filters/additional-filters";
import { useGetActors } from "./use-get-actors";
import { filterParamSelector } from "../../utils/filters";
import { useFeatureFlags } from "src/hooks/use-configuration";

export const mapTaskConditionLabels = {
  [TaskOwnershipFilters.ASSIGNED_TO_ME]: "labels__task_condition_ASSIGNED_TO_ME",
  [TaskOwnershipFilters.REPORTED_BY_ME]: "labels__task_condition_REPORTED_BY_ME",
  [TaskOwnershipFilters.ASSIGNED_TO_MY_TEAMS]: "labels__task_condition_ASSIGNED_TO_MY_TEAMS"
};

enum DueDate {
  OVERDUE = "OVERDUE",
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  NEXT_SEVEN_DAYS = "NEXT_SEVEN_DAYS",
  SEVEN_DAYS_PLUS = "SEVEN_DAYS_PLUS"
}

export enum TaskType {
  MANUAL_TASK = "MANUAL_TASK",
  SERVICE_TASK = "SERVICE_TASK",
  AUTOMATED_TASK = "AUTOMATED_TASK",
  DEPARTURE_CLEANING = "DEPARTURE_CLEANING",
  STAYOVER_CLEANING = "STAYOVER_CLEANING",
  GUEST_TASK = "GUEST_TASK",
  FOOD_AND_BEVERAGE_ORDER = "FOOD_AND_BEVERAGE_ORDER",
  SHOP_ORDER = "SHOP_ORDER"
}

const additionalReservationFilterNames = (foodAndBeverage: boolean) => {
  return {
    status: Object.keys(TaskStatus)
      .map((item) => TaskStatus[item as keyof typeof TaskStatus])
      .reverse(),
    priority: SortedTaskPriorities,
    due: Object.keys(DueDate).map((item) => DueDate[item as keyof typeof DueDate]),
    type: Object.keys(TaskType)
      .map((item) => TaskType[item as keyof typeof TaskType])
      .filter((type) => foodAndBeverage || type !== TaskType.FOOD_AND_BEVERAGE_ORDER)
  };
};

interface TaskToolbarProps extends BaseTableFiltersProps {
  taskConditionNumbers: TaskConditionsResponse;
  filterMap: FilterMapInterface;
  onFilterMapChange: (filterMap: FilterMapInterface) => void;
  currentActorId: string;
  removeFilter: (val: Array<string>, isReset?: boolean) => void;
}

export const TaskToolbar: FC<TaskToolbarProps> = ({
  taskConditionNumbers,
  filterMap,
  onFilterMapChange,
  currentActorId,
  removeFilter
}) => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { openNewTask } = useTaskModal({});
  const { data: actorsData } = useGetActors();
  const { spacing } = useTheme();

  const { foodAndBeverageEnabled } = useFeatureFlags();

  const actors = actorsData?.GetActors;

  const employeeOptions = useMemo(
    () =>
      actors?.map((item) => ({
        id: `${item.actorId}`,
        label: item.displayName ?? ""
      })) ?? [],
    [actors]
  );

  const filterValuesMapper = useMemo(() => {
    const employeeValuesMap = (actors || []).reduce(
      (acc, next) => {
        if (next?.actorId) {
          acc[next.actorId] = next.displayName ?? "";
        }
        return acc;
      },
      {} as { [key: string]: string }
    );
    return {
      [AutocompleteFilterType.ASSIGNED_TO]: employeeValuesMap,
      [AutocompleteFilterType.REPORTED_BY]: employeeValuesMap,
      [AutocompleteFilterType.ASSIGNED_TO_TEAMS_OF]: employeeValuesMap
    };
  }, [actors]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: spacing(2, 2.5),
        gap: spacing(1.25)
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: spacing(1.25),
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%"
          }}
        >
          <TableFilter filterMap={filterMap} onFilterMapChange={onFilterMapChange}>
            <EmployeeAutocompleteFilters
              filterMap={filterMap}
              onFilterMapChange={onFilterMapChange}
              removeFilter={removeFilter}
              allowedFilters={[
                AutocompleteFilterType.ASSIGNED_TO,
                AutocompleteFilterType.REPORTED_BY,
                AutocompleteFilterType.ASSIGNED_TO_TEAMS_OF
              ]}
              employeeOptions={employeeOptions}
            />
            <Divider />
            <AdditionalFilters
              filterMap={filterMap}
              onFilterMapChange={onFilterMapChange}
              additionalFilterNames={additionalReservationFilterNames(foodAndBeverageEnabled)}
            />
          </TableFilter>
          <Box
            sx={{ display: "flex", gap: 1.25, width: isMobile ? "100%" : "auto", flexWrap: "wrap" }}
          >
            {taskConditionNumbers &&
              Object.keys(taskConditionNumbers).map((condition) => (
                <QuickFilterCheckbox
                  key={condition}
                  isSingleValue
                  condition={condition}
                  value={currentActorId}
                  count={taskConditionNumbers[condition as keyof typeof taskConditionNumbers]}
                  filterMap={filterMap}
                  filterType={FilterObjectType.AUTOCOMPLETE}
                  filter={{
                    name: filterParamSelector(condition) ?? "",
                    type: FilterObjectType.AUTOCOMPLETE,
                    operator: FilterOperator.Equality
                  }}
                  label={t(
                    mapTaskConditionLabels[condition as keyof typeof mapTaskConditionLabels]
                  )}
                  onFilterMapChange={onFilterMapChange}
                />
              ))}
          </Box>
        </Box>
        {!isMobile && (
          <Button
            color="primary"
            size="medium"
            startIcon={<Plus />}
            onClick={openNewTask}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("buttons__create_task")}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          pt: 0,
          display: "flex",
          alignItems: "center"
        }}
      >
        <AddedFilters
          filterMap={filterMap}
          removeFilter={removeFilter}
          filterValuesMapper={filterValuesMapper}
        />
      </Box>
    </Box>
  );
};
