import { Chip, Heading3 } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import { FC } from "react";
import { ProfileActions } from "src/features/profiles/profile-drawer/profile-actions";
import { genderPronounsLabel } from "./reservation-details-guest-insight-utils";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { GuestType } from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { Person } from "src/domain/person";

interface PersonCardTitleProps {
  guest: Person;
  role: GuestType;
}

export const PersonCardHeader: FC<PersonCardTitleProps> = ({ guest, role }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  return (
    <Grid2 container sx={{ width: "100%", px: 3, paddingTop: 3 }} alignItems="center">
      <Grid2 size={{ xs: 11 }}>
        <Grid2 container direction="row" alignItems="center" spacing={2}>
          <Grid2>
            <Grid2 container direction="row" alignItems="center" spacing={1}>
              {guest?.gender && (
                <Grid2>
                  <Heading3 color={theme.palette.grey[500]}>
                    {genderPronounsLabel(guest.gender, t)}
                  </Heading3>
                </Grid2>
              )}
              <Grid2>
                <Heading3 sx={{ color: theme.palette.text.primary }}>
                  {guest.firstName + " " + guest.lastName}
                </Heading3>
              </Grid2>
            </Grid2>
          </Grid2>
          <Chip color="default" size="small" label={t(`labels__reservation_${role}`)} />
          {(role === GuestType.SAME_GUEST || role === GuestType.PRIMARY_GUEST) && (
            <Chip
              size="small"
              color="info"
              label={
                guest.isRecurring
                  ? t("labels__reservation__details__overview__guests__recurring")
                  : t("labels__reservation__details__overview__guests__new")
              }
            />
          )}
          {guest?.isBlacklisted && (
            <Chip size="small" color="error" label={t("labels__profile_blacklisted")} />
          )}
        </Grid2>
      </Grid2>
      {guest?.userProfileId && guest?.userProfileId > 0 && (
        <Grid2 size={{ xs: 1 }}>
          <Grid2
            container
            sx={{
              justifyContent: "end"
            }}
          >
            <ProfileActions
              isBlacklisted={guest?.isBlacklisted}
              profileId={guest?.userProfileId}
              menuPlacement="bottom-start"
            />
          </Grid2>
        </Grid2>
      )}
    </Grid2>
  );
};
