import { useMediaQuery, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

export const responsiveVariables: {
  firstDesktopSize: Breakpoint;
} = {
  firstDesktopSize: "md"
};
export const useIsMobile = () => {
  const theme = useTheme();

  return !useMediaQuery(theme.breakpoints.up(responsiveVariables.firstDesktopSize));
};

export const useIsTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    window.matchMedia("(pointer: coarse)").matches
  );
};
