import React, { FC, useMemo } from "react";
import { Grid2, useTheme } from "@mui/material";
import { UserAvatar } from "../../components/user-avatar";
import { Chip, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import { ConversationDetails } from "../domain/conversation";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { EmailOutlined, WhatsApp } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { capitalizeFirstLetter } from "../utils/utils";

interface ChatPreviewHeaderProps {
  conversation: ConversationDetails;
}

export const ChatPreviewHeader: FC<ChatPreviewHeaderProps> = ({ conversation }) => {
  const { palette } = useTheme();
  const userInitials = useMemo(() => {
    if (conversation.guestIdentity?.firstName && conversation.guestIdentity?.lastName) {
      return `${conversation.guestIdentity?.firstName?.charAt(
        0
      )}${conversation.guestIdentity?.lastName?.charAt(0)}`;
    }
    return "";
  }, [conversation.guestIdentity]);
  const { t } = useTranslationWrapper();

  return (
    <Grid2 spacing={1.5} container direction="column">
      <Grid2 container direction="row" alignItems="center" spacing={1}>
        <UserAvatar
          lettersToDisplay={userInitials}
          sx={{
            bgcolor: palette.common.white,
            border: 1,
            borderColor: palette.common.black,
            color: palette.common.black,
            height: 40,
            width: 40,
            p: 2
          }}
        />
        <Heading2>{conversation.guest.displayName}</Heading2>
      </Grid2>
      <Grid2 container spacing={1}>
        {conversation.guestIdentity?.gender && (
          <Chip
            size="medium"
            color="default"
            variant="outlined"
            label={t(
              "labels__gender_" +
                capitalizeFirstLetter(conversation.guestIdentity?.gender?.toLowerCase() ?? "")
            )}
          />
        )}
        <Chip
          color="info"
          size="medium"
          label={t(
            conversation.guestIdentity?.reservations &&
              conversation.guestIdentity?.reservations.length > 1
              ? "labels__recurring"
              : "labels__new"
          )}
        />
      </Grid2>

      {conversation.guestIdentity?.phone && (
        <Grid2
          container
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ color: grey["700"] }}
        >
          <WhatsApp />
          <Paragraph color="inherit"> {conversation.guestIdentity?.phone}</Paragraph>
        </Grid2>
      )}

      {conversation.guestIdentity?.email && (
        <Grid2
          container
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ color: grey["700"] }}
        >
          <EmailOutlined />
          <Paragraph color="inherit"> {conversation.guestIdentity?.email}</Paragraph>
        </Grid2>
      )}
    </Grid2>
  );
};
