import { BaseEntity } from "./base-entity";
import { Price } from "./price";
import { AdditionalServicesAvailabilityResponse } from "./additional-services-availability";

export interface Service extends BaseEntity {
  price: Price;
  code: string; ///AdditionalServicesCodes;
  name: string;
  quantity: number;
  serviceId?: string;
  dates: string[];
}

export enum Granularity {
  WHOLE_STAY = "WHOLE_STAY",
  WHOLE_STAY_PAY_ONCE = "WHOLE_STAY_PAY_ONCE",
  ANY_DAY = "ANY_DAY",
  ANY_DAY_EXCEPT_ARRIVAL_DAY = "ANY_DAY_EXCEPT_ARRIVAL_DAY",
  ARRIVAL_DAY = "ARRIVAL_DAY",
  DEPARTURE_DAY = "DEPARTURE_DAY",
  LATE_CHECKOUT = "LATE_CHECKOUT",
  EARLY_CHECKIN = "EARLY_CHECKIN",
  UNKNOWN = "UNKNOWN"
}

export enum InteractionType {
  BOOLEAN = "BOOLEAN",
  COUNTER = "COUNTER",
  LATE_CHECKOUT_DIALOG = "LATE_CHECKOUT_DIALOG",
  EARLY_CHECKIN_DIALOG = "EARLY_CHECKIN_DIALOG",
  DATE_PICKER_DIALOG = "DATE_PICKER_DIALOG",
  INPUT = "INPUT"
}

export interface DateAndCount {
  date: string; // TODO LM-374 use a dedicated object for this.
  count: number;
}

export enum DefaultTargetAudience {
  CHILD = "CHILD",
  ADULT = "ADULT",
  ALL = "ALL",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE"
}

export enum DefaultDatePickerSelection {
  None = "NONE",
  All = "ALL"
}

export interface AdditionalServiceWithPrice extends AdditionalServicesAvailabilityResponse {
  price: Price;
  quantity: number;
  dates: string[];
}

export enum Visibility {
  GUEST_FLOW = "GUEST_FLOW",
  MY_STAY = "MY_STAY",
  BACKOFFICE = "BACKOFFICE"
  // Later: IBE
}
