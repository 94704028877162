import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { PersonGender } from "src/domain/person";
import { GuestType } from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { getI18nLanguage } from "../../../../../components/language-selector/language-selector";
import { ReservationTableDto } from "src/domain/reservation-table-dto";

export enum GuestInsightInfoTypes {
  CONTACT = "CONTACT",
  PRIVATE_ADDRESS = "PRIVATE_ADDRESS",
  PREFERENCES = "PREFERENCES",
  NOTES = "NOTES",
  LEGAL_DATA = "LEGAL_DATA",
  BILLING_ADDRESS = "BILLING_ADDRESS",
  ACCOUNT = "ACCOUNT"
}

export const getGuestInsightInfoIcon = (type: GuestInsightInfoTypes) => {
  switch (type) {
    case GuestInsightInfoTypes.CONTACT:
      return <PermContactCalendarOutlinedIcon />;
    case GuestInsightInfoTypes.PRIVATE_ADDRESS:
      return <PersonPinCircleOutlinedIcon />;
    case GuestInsightInfoTypes.PREFERENCES:
      return <RoomPreferencesOutlinedIcon />;
    case GuestInsightInfoTypes.LEGAL_DATA:
      return <PolicyOutlinedIcon />;
    case GuestInsightInfoTypes.BILLING_ADDRESS:
      return <ReceiptLongOutlinedIcon />;
    case GuestInsightInfoTypes.NOTES:
      return <ChatOutlinedIcon />;
    case GuestInsightInfoTypes.ACCOUNT:
      return <AccountBoxOutlinedIcon />;
    default:
      return <></>;
  }
};

export const genderPronounsLabel = (
  gender: PersonGender,
  translation: (translationlabel: string) => string
) => {
  return `(${translation("labels__pronouns__" + gender)})`;
};

export const regionNames = new Intl.DisplayNames([getI18nLanguage()], {
  type: "region"
});

export const guestSpecificUnits = (reservation: ReservationTableDto, actorId: string) => {
  const guestPreferences =
    actorId === GuestType.BOOKER
      ? reservation?.booker?.guestPreferences
      : reservation?.primaryGuest?.guestPreferences;

  return guestPreferences && Array.isArray(guestPreferences?.SPECIFIC_UNIT)
    ? [...guestPreferences.SPECIFIC_UNIT]
    : [];
};
