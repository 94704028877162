import { Grid2 } from "@mui/material";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useProperty } from "../../../hooks/use-property";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import React from "react";
import PageTitle from "../../../components/page-title";
import AdditionalServicesTable from "./additional-services-table";

const SettingsAdditionalServicesPage = () => {
  const { selectedPropertyPreview } = useProperty();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();

  return (
    <>
      {!isMobile && (
        <Grid2
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Grid2
            sx={{
              pb: 2.5
            }}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("title__additional_services")} />
          </Grid2>
        </Grid2>
      )}
      <AdditionalServicesTable />
    </>
  );
};

export default SettingsAdditionalServicesPage;
