import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import { FC } from "react";
import { NotificationLabelForAutocomplete } from "./notification-label-for-autocomplete";
import { UserProfile } from "src/domain/user-profile";
import { AutocompleteOption } from "src/components/autocomplete-sync-component";
import { InitialValuesForForm } from "./notification-send-modal";
import { FormikProps } from "formik";

interface NotificationSendModalAutocompleteItemProps {
  result: UserProfile[];
  option: AutocompleteOption;
  profileOptionItem:
    | {
        id: string;
        label: string;
      }
    | undefined;
  formik: FormikProps<InitialValuesForForm>;
}

export const NotificationSendModalAutocompleteItem: FC<
  NotificationSendModalAutocompleteItemProps
> = ({ result, option, profileOptionItem, formik }) => {
  const { spacing } = useTheme();
  return (
    <Grid2
      container
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: spacing(1)
      }}
    >
      <Grid2>
        <Paragraph>{profileOptionItem?.label}</Paragraph>
      </Grid2>
      <Grid2>
        <NotificationLabelForAutocomplete
          profile={result?.filter((profile: UserProfile) => profile.id === Number(option.id)) ?? []}
          channel={formik.values.channel.value}
        />
      </Grid2>
    </Grid2>
  );
};
