import * as Types from "../generated/graphql";

export type UnitGroupContractFragment = {
  __typename?: "UnitGroupContract";
  active?: boolean | null;
  capacity?: number | null;
  extraCapacity?: number | null;
  id?: string | null;
  name?: string | null;
  pmsId?: string | null;
  pmsPropertyId?: string | null;
  pmsUpdatedAt?: any | null;
  type?: string | null;
};

export const UnitGroupContractFragmentDoc = `
    fragment UnitGroupContract on UnitGroupContract {
  active
  capacity
  extraCapacity
  id
  name
  pmsId
  pmsPropertyId
  pmsUpdatedAt
  type
}
    `;
