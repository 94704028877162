import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "../../../store";
import {
  selectIsOpenSideNavigation,
  setIsOpenSideNavigation
} from "../../../slices/dashboard-sidebar";
import { SidebarItem } from "./sidebar-item";
import React from "react";
import { CollapseSidebarIcon } from "../../../assets/icons/collapse-sidebar-icon";
import { ExpandSidebarIcon } from "../../../assets/icons/expand-sidebar-icon";

export const DashboardSidebarCollapseButton = () => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const setOpenSideNavigation = (openSidebar: boolean) => {
    dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
  };

  return (
    <SidebarItem
      title={openSideNavigation ? t("labels__collapse") : t("labels__expand")}
      icon={openSideNavigation ? <CollapseSidebarIcon /> : <ExpandSidebarIcon />}
      isCollapsable={false}
      onClick={() => setOpenSideNavigation(!openSideNavigation)}
    />
  );
};
