import { useState } from "react";
import { useReleaseNote } from "./use-release-note";
import { ReleaseNotesButton } from "./release-notes-button";
import { ReleaseNotesDrawer } from "./release-notes-drawer";

export const ReleaseNotes = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const releaseNotes = useReleaseNote();

  return (
    <>
      <ReleaseNotesButton setOpenDrawer={setOpenDrawer} />
      <ReleaseNotesDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        releaseNotes={releaseNotes}
      />
    </>
  );
};
