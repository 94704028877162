import React, { FC, useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useSelector } from "react-redux";
import { selectKeycloakSliceUserInfo } from "../../slices/keycloak.slice";
import { useDispatch } from "../../store";
import { updateUserInfoLanguage } from "../../slices/user-info.slice";
import { LanguageSelector, TenantContext } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage, getTranslatedLanguageName } from "../../utils/language";
import { PopoverOrigin, useTheme } from "@mui/material";
import { useIsMobile } from "../../hooks/use-is-mobile";

export const DEFAULT_LANGUAGE = "EN";

export function twoLetterLanguageCode(language: string): string {
  return language.substring(0, 2);
}

export const getI18nLanguage = () =>
  i18n.language ? twoLetterLanguageCode(i18n.language) : DEFAULT_LANGUAGE;

interface LanguageSelectorComponentProps {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const LanguageSelectorComponent: FC<LanguageSelectorComponentProps> = ({
  anchorOrigin,
  transformOrigin
}) => {
  const { t } = useTranslationWrapper();
  const userInfo = useSelector(selectKeycloakSliceUserInfo);
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const { allowedBackofficeLanguages } = useContext(TenantContext);
  const language = getI18nSelectedLanguage();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const languageOptions =
    allowedBackofficeLanguages?.map((lang) => ({
      value: lang,
      label: getTranslatedLanguageName({
        code: twoLetterLanguageCode(lang),
        locale: language
      })
    })) ?? [];

  useEffect(() => {
    if (userInfo?.locale) {
      setLang(twoLetterLanguageCode(userInfo.locale));
    }
  }, [userInfo?.locale]);

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
      dispatch(updateUserInfoLanguage({ lang }));
    }
  }, [lang, dispatch]);

  return (
    <LanguageSelector
      label={t("labels__language")}
      langOptions={languageOptions}
      tooltipPlacement={isMobile ? "bottom" : "right"}
      onLangChange={(e) => setLang(twoLetterLanguageCode(e))}
      color={theme.palette.grey[600]}
      collapsed={isMobile}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    />
  );
};
