import { Box, Collapse, Divider, Grid2 } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Checkbox,
  Chip,
  Counter,
  formatRange,
  formatToIsoDate,
  Heading3,
  Input,
  isSameDaysInArray,
  Paragraph,
  ParagraphSmall,
  parseIsoDateString,
  ServicesDatePicker
} from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "../../../../../../components/price-preview/price-preview";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../../../hooks/use-property";
import { Dialog } from "./dialog";
import { ServiceModalsProps } from "./types";
import { multiplyPrices } from "../../../../../../utils/price";
import {
  DateAndCount,
  DefaultDatePickerSelection,
  DefaultTargetAudience
} from "../../../../../../domain/service";
import { emptyPrice } from "../../../../../../domain/price";
import { getI18nSelectedLanguage } from "src/utils/language";
import { AddOnType } from "src/domain/additional-services-availability";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles()((theme) => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  },
  dot: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    borderRadius: "50%",
    display: "inline-block",
    marginRight: theme.spacing(0.5)
  },
  selectedDot: {
    backgroundColor: theme.palette.primary.main
  },
  highlightedDot: {
    backgroundColor: theme.palette.grey[700]
  },
  legendContainer: {
    textAlign: "center",
    flexWrap: "nowrap"
  }
}));

export const ServiceDatesPickerDialog: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const [licensePlate, setLicensePlate] = useState(
    reservation?.primaryGuest.licensePlate ?? undefined
  );

  const initialDates: Date[] = useMemo(() => {
    if (service?.defaultDatePickerSelection === DefaultDatePickerSelection.All) {
      return (service.bookableDates || []).map(
        (dateAndCount: DateAndCount) => new Date(dateAndCount.date)
      );
    }
    return [];
  }, [service]);

  const initialCount: number = useMemo(() => {
    if (!service?.canBookMultiple) {
      return 1;
    }

    switch (service?.defaultTargetAudience) {
      case DefaultTargetAudience.ADULT:
        return reservation?.adultsCount || 1;
      case DefaultTargetAudience.CHILD:
        return reservation?.childrenCount || 0;
      case DefaultTargetAudience.ONE:
        return 1;
      case DefaultTargetAudience.TWO:
        return 2;
      case DefaultTargetAudience.THREE:
        return 3;
      case DefaultTargetAudience.FOUR:
        return 4;
      case DefaultTargetAudience.FIVE:
        return 5;
      case DefaultTargetAudience.ALL:
      default:
        return (reservation?.adultsCount ?? 1) + (reservation?.childrenCount ?? 0) || 1;
    }
  }, [reservation?.adultsCount, reservation?.childrenCount, service]);

  const [dates, setDates] = useState<Date[]>(initialDates);
  const [count, setCount] = useState<number>(initialCount);

  const bookedDates: Date[] = (service.bookedDates || []).map(
    (dateAndCount: any) => new Date(dateAndCount.date)
  );

  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: any) => new Date(dateAndCount.date)
  );

  const isAllSelected = useMemo(
    () => isSameDaysInArray(dates, availableDays),
    [dates, availableDays]
  );
  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    const values = dates.map((date) => ({
      date: formatToIsoDate(date),
      count: count
    }));
    if (service.addOnType === AddOnType.Parking) {
      onChange({ values, licensePlate });
    } else {
      onChange({ values });
    }
  }, [dates, onChange, count, licensePlate, service.addOnType]);

  return (
    <Dialog
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      title={t("labels__wizard_select_dates")}
      content={
        <Box className={classes.dialogContentContainer}>
          <Heading3 align="center" gutterBottom>
            {formatRange(
              selectedProperty?.details.timeZone,
              getI18nSelectedLanguage(),
              reservation?.arrival,
              reservation?.departure
            )}
          </Heading3>
          <Grid2 container className={classes.legendContainer} pb={1}>
            <Grid2 size={{ xs: 12 }}>
              <span className={`${classes.dot} ${classes.highlightedDot}`} />
              <ParagraphSmall display="inline">{t("labels__hihlighted_dates")}</ParagraphSmall>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <span className={`${classes.dot} ${classes.selectedDot}`} />
              <ParagraphSmall display="inline">{t("labels__selected_dates")}</ParagraphSmall>
            </Grid2>
          </Grid2>

          <Box
            sx={{
              m: 2,
              mt: 0
            }}
          >
            <ServicesDatePicker
              bookedDates={bookedDates}
              selectedDates={dates}
              onChange={setDates}
              selectedLanguage={getI18nSelectedLanguage()}
              availableDates={availableDays}
              defaultCalendarMonth={
                reservation?.arrival ? parseIsoDateString(reservation.arrival) : undefined
              }
            />
          </Box>

          <Box
            sx={{
              px: 2.5
            }}
          >
            <Checkbox
              id="servicesDatePickerDialog"
              color="primary"
              onChange={(event) => {
                if ((event.target as any).checked) {
                  setDates(availableDays);
                } else {
                  setDates([]);
                }
              }}
              checked={isAllSelected}
              title={t("labels__wizard_select_all_dates")}
            />
          </Box>

          <Collapse in={!!(dates.length && service.canBookMultiple)}>
            <Box
              sx={{
                py: 2
              }}
            >
              <Paragraph textAlign={"center"} pb={1}>
                {t("labels__wizard_counter")}
              </Paragraph>
              <Counter
                classes={{ root: classes.counter }}
                initialCount={initialCount}
                minValue={1}
                maxValue={service.maximum}
                onCountChange={setCount}
              />
            </Box>
            <Divider />
          </Collapse>
          {service.addOnType === AddOnType.Parking && (
            <Grid2
              container
              direction="column"
              spacing={2}
              p={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid2 size={{ xs: 12 }}>
                <Input
                  onChange={(e) => setLicensePlate(e.target.value)}
                  variant={"outlined"}
                  value={licensePlate}
                  label={t("labels__license_plate")}
                />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Chip
                  color="info"
                  label={t("labels__license_plate_services_info")}
                  leftIcon={<InfoOutlinedIcon />}
                  sx={{
                    p: 2,
                    display: "flex",
                    width: "100%",
                    "& .MuiChip-label": {
                      overflow: "visible"
                    }
                  }}
                />
              </Grid2>
            </Grid2>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              px: 2.5,
              py: 2
            }}
          >
            <PricePreview
              price={multiplyPrices(service?.price ?? emptyPrice(), dates.length * count)}
            />
          </Box>
        </Box>
      }
      buttonLabel={t("labels__wizard_confirm")}
    />
  );
};
