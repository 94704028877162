import { api } from "../GetServiceConfigs.generated";

export const getServiceConfigsEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetServiceConfigs: {
      providesTags: ["ServiceConfigs"]
    }
  }
});

export const { useGetServiceConfigsQuery: useGetServiceConfigsQueryEnhanced } =
  getServiceConfigsEnhanced;
