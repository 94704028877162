import { FC, useCallback, useEffect } from "react";
import { Box, Grid2, useTheme } from "@mui/material";
import PageTitle from "../components/page-title";
import { useProperty } from "../hooks/use-property";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "../store";
import { fetchAllBoxes, selectAllBoxes } from "../slices/box.slice";
import { useSearchFilterParams } from "src/components/table/hooks/use-search-filter-params";
import { FilterMapInterface } from "src/components/table/filters/table-filters";
import { FreeTextFilter } from "src/components/table/filters/free-text-filter";
import { BoxTabs } from "src/features/boxes/box-tabs";

export const BoxesPage: FC = () => {
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const boxes = useSelector(selectAllBoxes);
  const dispatch = useDispatch();
  const { filterMap, setFilterMap } = useSearchFilterParams();

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      dispatch(fetchAllBoxes({ propertyId: selectedProperty.propertyId }));
    }
  }, [dispatch, selectedProperty?.propertyId]);

  const refreshList = useCallback(() => {
    dispatch(fetchAllBoxes({ propertyId: selectedProperty?.propertyId ?? "" }));
  }, [dispatch, selectedProperty?.propertyId]);

  const onFilterMapChange = useCallback(
    (filters: FilterMapInterface) => {
      setFilterMap(filters);
    },
    [setFilterMap]
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: "100%"
        }}
      >
        <Grid2 container>
          <Grid2
            container
            size={{ xs: 12 }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("title__boxes")} />
          </Grid2>
          <Grid2
            container
            sx={{
              alignItems: "center",
              marginTop: 1,
              width: "100%"
            }}
          >
            <Grid2 size={{ xs: 12 }} style={{ maxWidth: 480 }}>
              <Grid2 container>
                <FreeTextFilter filterMap={filterMap} onFilterMapChange={onFilterMapChange} />
              </Grid2>
            </Grid2>
          </Grid2>
          <BoxTabs boxes={boxes} refreshList={refreshList} />
        </Grid2>
      </Box>
    </>
  );
};
