import { Button, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { Box, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { DialogModal } from "src/components/submit-modal/dialog";
import { useBlacklistProfile } from "src/hooks/use-blacklist-profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useUnBlacklistProfile } from "src/hooks/use-unblacklist-profile";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmIdentity } from "./confirm-identity";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";

interface ConfirmIdentityModalProps {
  reservation: ReservationDetailsDTO;
  documentUploadEnabled: boolean;
  propertyId: string;
  isOpen: boolean;
  onClose: () => void;
}

enum ConfirmIdentityModalActions {
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  UNBLACKLIST_PROFILE = "UNBLACKLIST_PROFILE",
  BLACKLIST_PROFILE = "BLACKLIST_PROFILE"
}

export const ConfirmIdentityModal: FC<ConfirmIdentityModalProps> = ({
  reservation,
  documentUploadEnabled,
  propertyId,
  isOpen,
  onClose
}) => {
  const { t } = useTranslationWrapper();
  const [blacklistProfile, resultBlacklistProfile] = useBlacklistProfile();
  const [unBlacklistProfile, resultUnBlacklistProfile] = useUnBlacklistProfile();
  const [performAction, resultAction] = usePerformReservationAction();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      resultBlacklistProfile.isSuccess ||
      resultUnBlacklistProfile.isSuccess ||
      resultAction.isSuccess
    ) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    } else if (
      resultBlacklistProfile.isError ||
      resultUnBlacklistProfile.isError ||
      resultAction.isError
    ) {
      dispatch(
        openBanner({
          type: "error",
          title: t("labels__action_failed_to_perform")
        })
      );
    }
  }, [
    dispatch,
    resultAction.isSuccess,
    resultBlacklistProfile.isSuccess,
    resultUnBlacklistProfile.isSuccess,
    resultAction.isError,
    resultBlacklistProfile.isError,
    resultUnBlacklistProfile.isError,
    t
  ]);

  const actionPerformed = useCallback(
    (action: ConfirmIdentityModalActions) => {
      switch (action) {
        case ConfirmIdentityModalActions.BLACKLIST_PROFILE:
          return blacklistProfile({
            profileId: reservation.primaryGuest.userProfileId ?? 0,
            reservationId: reservation.id
          });
        case ConfirmIdentityModalActions.UNBLACKLIST_PROFILE:
          return unBlacklistProfile({
            profileId: reservation.primaryGuest.userProfileId ?? 0,
            reservationId: reservation.id
          });
        case ConfirmIdentityModalActions.CONFIRMED:
        case ConfirmIdentityModalActions.DECLINED:
          return performAction({
            reservationId: reservation.id,
            action: AllowedActionEnum.CHANGE_ID_CHECK_STATUS,
            payload: { status: action }
          });
      }
    },
    [
      reservation.id,
      reservation.primaryGuest.userProfileId,
      performAction,
      blacklistProfile,
      unBlacklistProfile
    ]
  );

  return (
    <DialogModal isOpen={isOpen} handleClose={onClose}>
      <DialogTitle sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <ParagraphBold>
            {t("labels__reservation_action_title_CHANGE_ID_CHECK_STATUS")}
          </ParagraphBold>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ConfirmIdentity
          files={reservation?.files}
          primaryGuest={reservation?.primaryGuest}
          documentUploadEnabled={documentUploadEnabled}
          propertyId={propertyId}
          idCheckStatus={reservation.idCheckStatus}
        />
      </DialogContent>
      <DialogActions sx={{ width: "100%", justifyContent: "center", display: "unset" }}>
        <Box>
          {reservation.files.length > 0 && (
            <Button
              variant="primary"
              color="error"
              fullWidth
              onClick={() => {
                actionPerformed(ConfirmIdentityModalActions.DECLINED);
                onClose();
              }}
            >
              {t("labels__decline")}
            </Button>
          )}
          {reservation.files.length > 0 && (
            <Button
              variant="primary"
              color="success"
              fullWidth
              sx={{ mt: 1 }}
              onClick={() => {
                actionPerformed(ConfirmIdentityModalActions.CONFIRMED);
                onClose();
              }}
            >
              {t("labels__approve")}
            </Button>
          )}
          <Button
            variant="secondary"
            fullWidth
            sx={{ my: 1 }}
            onClick={() => {
              actionPerformed(
                reservation.primaryGuest.isBlacklisted
                  ? ConfirmIdentityModalActions.UNBLACKLIST_PROFILE
                  : ConfirmIdentityModalActions.BLACKLIST_PROFILE
              );
              onClose();
            }}
          >
            {reservation.primaryGuest.isBlacklisted
              ? t("labels__unblacklist_profile")
              : t("labels__blacklist_profile")}
          </Button>
        </Box>
      </DialogActions>
    </DialogModal>
  );
};
