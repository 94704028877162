import { FC } from "react";
import { Grid2 } from "@mui/material";
import PageTitle from "../components/page-title";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useReleaseNote } from "../features/release-notes/use-release-note";
import { ReleaseNotesCard } from "../features/release-notes/release-notes-card";

const ReleaseNotesPage: FC = () => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const formattedPrismicDataToBeUsed = useReleaseNote();

  return (
    <>
      {!isMobile && (
        <Grid2
          container
          spacing={3}
          size={{ xs: 12 }}
          sx={{
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Grid2
            sx={{
              mb: 2.5
            }}
          >
            <PageTitle
              title={t("labels__release_notes")}
              subTitle={`${t("release__what__new")}?`}
            />
          </Grid2>
        </Grid2>
      )}
      <ReleaseNotesCard releaseNotes={formattedPrismicDataToBeUsed} page />
    </>
  );
};

export default ReleaseNotesPage;
