import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import { Box, Grid2, useTheme } from "@mui/material";
import { pickerDateSelector } from "../../slices/date-picker";
import { useSelector } from "../../store";
import { DatePickerFilter } from "../../components/date-picker-filter/date-picker-filter";
import { useProperty } from "../../hooks/use-property";
import { BreakfastCard } from "./breakfast-card";
import { BreakfastFilter } from "./breakfast-filter";
import { Report, ReportStatus } from "../../domain/report";
import { useDateSearchParams } from "../../hooks/use-date-search-params";
import OverviewBreakfastCard from "../../components/dashboard/overview/overview-breakfast-card";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { SearchField } from "../../components/search-field/search-field";
import { useFetchBreakfast } from "./use-fetch-breakfast";

export const BreakfastPage: FC = () => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const navigate = useNavigateWithPropertyId();
  const date = useSelector(pickerDateSelector);
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const { breakfastReports, fetchBreakfastAction } = useFetchBreakfast({
    propertyId: selectedProperty?.propertyId,
    date
  });

  const countForBreakfastCondition = breakfastReports.reduce(
    (acc, breakfastReport) => {
      if (breakfastReport.performed) {
        acc.OBTAINED += 1;
      } else {
        acc.BOOKED += 1;
      }
      return acc;
    },
    { BOOKED: 0, OBTAINED: 0 }
  );

  const [statusFilter, setStatusFilter] = useState<ReportStatus | null>(ReportStatus.BOOKED);
  const [freeTextSearch, setFreeTextSearch] = useState<string>();
  const setConditionFilter = useCallback(
    (status: ReportStatus) => {
      if (status === statusFilter) {
        setStatusFilter(null);
      } else {
        setStatusFilter(status);
      }
    },
    [statusFilter]
  );

  const onDateChange = useCallback(
    (date: string) => {
      if (!selectedProperty?.propertyId) {
        return;
      }
      return fetchBreakfastAction({
        propertyId: selectedProperty?.propertyId,
        date
      });
    },
    [selectedProperty, fetchBreakfastAction]
  );
  useDateSearchParams(onDateChange);

  const filteredBreakfastReports: Report[] = useMemo(() => {
    const statusFilteredReports: Report[] = statusFilter
      ? breakfastReports.filter((report) => {
          return statusFilter === ReportStatus.BOOKED ? !report.performed : !report.performed;
        })
      : breakfastReports;

    return freeTextSearch
      ? statusFilteredReports.filter(
          (report) =>
            report.unitId?.toLowerCase().includes(freeTextSearch.toLowerCase()) ||
            (report.primaryGuestFirstName + " " + report.primaryGuestLastName)
              .toLowerCase()
              .includes(freeTextSearch.toLowerCase())
        )
      : statusFilteredReports;
  }, [breakfastReports, statusFilter, freeTextSearch]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: "100%"
        }}
      >
        <Grid2
          size={{ xs: 12 }}
          sx={{
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <PageTitle subTitle={selectedPropertyPreview} title={t("labels__breakfast")} />
          <Grid2
            container
            spacing={3}
            sx={{
              alignItems: "center",
              pt: 1,
              pb: 3
            }}
          >
            <Grid2 style={{ minWidth: 400 }}>
              <SearchField
                value={freeTextSearch ?? ""}
                onChange={setFreeTextSearch}
                placeholder={t("labels__breakfast_search")}
              />
            </Grid2>
            <Grid2>
              <BreakfastFilter
                value={statusFilter ?? undefined}
                onConditionSelected={setConditionFilter}
                countForBreakfastCondition={countForBreakfastCondition}
              />
            </Grid2>
            <Grid2>
              <DatePickerFilter timeZone={selectedProperty?.details.timeZone ?? ""} />
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <OverviewBreakfastCard navigate={navigate} />
          </Grid2>
          <Grid2
            container
            size={{ xs: 12 }}
            spacing={2}
            sx={{
              py: 3
            }}
          >
            {filteredBreakfastReports.map((report) => (
              <BreakfastCard
                key={report.reservationId + "_" + report.serviceId}
                propertyId={selectedProperty?.propertyId ?? ""}
                report={report}
              />
            ))}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};
