import { FC, ReactNode, useCallback, useEffect } from "react";
import { Drawer, IconButton } from "@mui/material";
import { useDispatch } from "../../store";
import { responsiveVariables, useIsMobile } from "../../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../../slices/dashboard-sidebar";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectDrawerItemIndex, selectIsTopDrawer } from "../../slices/drawer-stack.slice";

interface DrawerDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  drawerId: string;
}

export const DrawerDetails: FC<DrawerDetailsProps> = ({ children, isOpen, onClose, drawerId }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { breakpoints, spacing, palette } = useTheme();
  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isOpen || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [setOpenSideNavigation, isMobile, isOpen]);

  const drawerIndex = useSelector((state) => selectDrawerItemIndex(state, drawerId));
  const isTopDrawer = useSelector((state) => selectIsTopDrawer(state, drawerId));

  return (
    <Drawer
      PaperProps={{
        sx: {
          zIndex: 1200 + drawerIndex,
          [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
            width: "100%"
          },
          [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
            width: `${75 - drawerIndex * 2}%`
          },
          overflowY: "visible",
          bgcolor: "background.default",
          boxShadow:
            "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;",
          "&:hover": { bgcolor: "background.default" }
        }
      }}
      hideBackdrop
      variant="persistent"
      open={isOpen}
      anchor={isMobile ? "bottom" : "right"}
    >
      {isTopDrawer && (
        <IconButton
          sx={{
            [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
              bgcolor: palette.common.black,
              position: "absolute",
              width: spacing(4),
              height: spacing(4),
              top: spacing(2.5),
              right: spacing(2),
              zIndex: 101,
              color: palette.common.white,
              borderRadius: "10%",
              boxShadow:
                "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;",
              "&:hover": { bgcolor: palette.background.default, color: palette.common.black }
            },
            [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
              position: "fixed",
              top: 0,
              marginLeft: -5,
              bgcolor: palette.background.default,
              boxShadow:
                "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;",
              borderRadius: " 50% 0 0 50%",
              "&:hover": { bgcolor: palette.background.default }
            }
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}

      {isOpen ? children : <> </>}
    </Drawer>
  );
};
