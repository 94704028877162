import { Chip, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid2, Paper, useTheme } from "@mui/material";
import { FC } from "react";
import { Person } from "src/domain/person";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface PersonCardInfoGuestNotesProps {
  guest: Person;
}

export const PersonCardInfoGuestNotes: FC<PersonCardInfoGuestNotesProps> = ({ guest }) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  return (
    <>
      {guest.guestPreferencesText && (
        <Paragraph sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
          {guest.guestPreferencesText}
        </Paragraph>
      )}
      {guest.guestNotes &&
        guest?.guestNotes?.map((note) => (
          <Grid2
            size={{ xs: 12 }}
            key={note.pmsId}
            sx={{
              pt: 1
            }}
          >
            <Paper
              sx={{
                p: theme.spacing(1.5),
                border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`
              }}
              elevation={0}
            >
              <Grid2 container direction="column">
                <Grid2>
                  <Grid2
                    container
                    direction="row"
                    sx={{
                      alignItems: "center"
                    }}
                  >
                    {note.title && (
                      <Grid2>
                        <Paragraph>{note.title}</Paragraph>
                      </Grid2>
                    )}
                    <Grid2 ml={1}>
                      <Chip
                        label={t(`labels__notes_category_${note.pmsProfileNoteCategoryId}`)}
                        size="large"
                        color="info"
                      />
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2>
                  <ParagraphSmall sx={{ color: theme.palette.grey["600"] }}>
                    {note.text}
                  </ParagraphSmall>
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
        ))}
      {(!guest?.guestNotes || guest?.guestNotes?.length === 0) && !guest.guestPreferencesText && (
        <ParagraphSmall sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
          {t("labels__no__preferences_text__stored")}
        </ParagraphSmall>
      )}
    </>
  );
};
