import { twoLetterLanguageCode } from "src/components/language-selector/language-selector";
import { NotificationTemplates } from "src/domain/reservation-table-dto";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";

export const getDefaultActor = (reservation: ReservationDetailsDTO, template: string) => {
  switch (template) {
    case NotificationTemplates.RESERVATION_CREATED:
    case NotificationTemplates.CHECKIN_INTRODUCTION:
    case NotificationTemplates.PRE_CHECKIN_REMINDER:
    case NotificationTemplates.CHECKIN:
    case NotificationTemplates.CHECKIN_REMINDER:
    case NotificationTemplates.CHECKED_IN:
    case NotificationTemplates.RESERVATION_CANCELED:
    case NotificationTemplates.CHECKOUT_INTRODUCTION:
    case NotificationTemplates.CHECKOUT_FOLLOW_UP:
    case NotificationTemplates.INVOICE:
    case NotificationTemplates.CHECKOUT_ALERT:
    case NotificationTemplates.RESEND_GUESTFLOW_LINK:
      return {
        id: reservation.primaryGuest.userProfileId?.toString(),
        label: `${reservation?.primaryGuest.firstName} ${reservation?.primaryGuest.lastName}`
      };
    case NotificationTemplates.BOOKING_REMINDER:
    case NotificationTemplates.BOOKING_CREATED:
      return {
        id: reservation.booker.userProfileId?.toString(),
        label: `${reservation?.booker.firstName} ${reservation?.booker.lastName}`
      };
    case NotificationTemplates.COMPANION_ADDED:
      return {
        id: reservation.travelBuddies[0].userProfileId?.toString(),
        label: `${reservation?.travelBuddies[0].firstName} ${reservation?.travelBuddies[0].lastName}`
      };
  }
};

export const getNotificationInfo = (
  reservation: ReservationDetailsDTO,
  template: string,
  info: string
) => {
  switch (template) {
    case NotificationTemplates.RESERVATION_CREATED:
    case NotificationTemplates.CHECKIN_INTRODUCTION:
    case NotificationTemplates.PRE_CHECKIN_REMINDER:
    case NotificationTemplates.CHECKIN:
    case NotificationTemplates.CHECKIN_REMINDER:
    case NotificationTemplates.CHECKED_IN:
    case NotificationTemplates.RESERVATION_CANCELED:
    case NotificationTemplates.CHECKOUT_INTRODUCTION:
    case NotificationTemplates.CHECKOUT_FOLLOW_UP:
    case NotificationTemplates.INVOICE:
    case NotificationTemplates.CHECKOUT_ALERT:
    case NotificationTemplates.RESEND_GUESTFLOW_LINK:
      return reservation.primaryGuest?.[info];
    case NotificationTemplates.BOOKING_REMINDER:
    case NotificationTemplates.BOOKING_CREATED:
      return reservation.booker?.[info];
    case NotificationTemplates.COMPANION_ADDED:
      return reservation.travelBuddies[0]?.[info];
    default:
      return null;
  }
};

export const getDefaultLangugage = (reservation: ReservationDetailsDTO, template: string) => {
  switch (template) {
    case NotificationTemplates.RESERVATION_CREATED:
    case NotificationTemplates.CHECKIN_INTRODUCTION:
    case NotificationTemplates.PRE_CHECKIN_REMINDER:
    case NotificationTemplates.CHECKIN:
    case NotificationTemplates.CHECKIN_REMINDER:
    case NotificationTemplates.CHECKED_IN:
    case NotificationTemplates.RESERVATION_CANCELED:
    case NotificationTemplates.CHECKOUT_INTRODUCTION:
    case NotificationTemplates.CHECKOUT_FOLLOW_UP:
    case NotificationTemplates.INVOICE:
    case NotificationTemplates.CHECKOUT_ALERT:
    case NotificationTemplates.RESEND_GUESTFLOW_LINK:
      return twoLetterLanguageCode(
        reservation.primaryGuest?.preferredLanguage?.toUpperCase() ?? ""
      );
    case NotificationTemplates.BOOKING_REMINDER:
    case NotificationTemplates.BOOKING_CREATED:
      return twoLetterLanguageCode(reservation.booker?.preferredLanguage?.toUpperCase() ?? "");
    case NotificationTemplates.COMPANION_ADDED:
      return twoLetterLanguageCode(
        reservation.travelBuddies[0]?.preferredLanguage?.toUpperCase() ?? ""
      );
  }
};
