import Paper from "@mui/material/Paper";
import { Grid2, useTheme } from "@mui/material";
import {
  EmptyState,
  formatDate,
  Heading2,
  Paragraph,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { FC } from "react";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Profile } from "../../types/profile";
import { useProperty } from "src/hooks/use-property";
import { regionNames } from "../../../reservations/reservation-details/reservation-details-tabs/guest-insights/reservation-details-guest-insight-utils";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ProfileDrawerCardProps {
  profileInformation: Profile | undefined | null;
}

export const ProfileDrawerPersonalInformationCard: FC<ProfileDrawerCardProps> = ({
  profileInformation
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  return (
    <Grid2 size={{ xs: 12 }}>
      <Paper elevation={0}>
        <Grid2
          container
          sx={{
            gap: 3,
            p: 3,
            flexDirection: "column"
          }}
        >
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              aligns: "top"
            }}
          >
            <Grid2>
              <Heading2>{t(`labels__profiles_personal_information`)}</Heading2>
            </Grid2>
          </Grid2>
          {profileInformation ? (
            <Grid2 container direction="row" spacing={2}>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_title")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>
                      {profileInformation.title
                        ? t(`labels__pronouns__${profileInformation.title}`)
                        : ""}
                    </Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_firstName")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{profileInformation.firstName}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_lastName")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{profileInformation.lastName}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_phoneNumber")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>
                      <Paragraph>{profileInformation.phone}</Paragraph>
                    </Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_emailAddress")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{profileInformation.email}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_nationality")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    {profileInformation?.nationality && (
                      <Paragraph>{regionNames.of(profileInformation?.nationality)}</Paragraph>
                    )}
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_dateOfBirth")}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>
                      {formatDate(
                        profileInformation.birthdate,
                        language,
                        selectedProperty?.details?.timeZone ?? ""
                      )}
                    </Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              {features?.licensePlateCheckinWizardEnabled && (
                <Grid2 size={{ xs: 12 }}>
                  <Grid2 container direction="row">
                    <Grid2 size={{ xs: 4 }}>
                      <Paragraph sx={{ color: theme.palette.grey[600] }}>
                        {t("labels__profiles_license_plate")}
                      </Paragraph>
                    </Grid2>
                    <Grid2 size={{ xs: 8 }}>
                      <Paragraph>{profileInformation.licensePlate}</Paragraph>
                    </Grid2>
                  </Grid2>
                </Grid2>
              )}
            </Grid2>
          ) : (
            <EmptyState title="No information" />
          )}
        </Grid2>
      </Paper>
    </Grid2>
  );
};
