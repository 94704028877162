import { Chip, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid2, Paper, useTheme } from "@mui/material";
import { FC } from "react";
import { DeliveryLocationType, FoodAndBeverageOrder } from "src/domain/reservation-table-dto";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface FoodAndBeverageOrdersWidgetInfoProps {
  order: Array<FoodAndBeverageOrder | null>;
}

export const FoodAndBeverageOrdersWidgetInfo: FC<FoodAndBeverageOrdersWidgetInfoProps> = ({
  order
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  return (
    <>
      <Grid2 container direction="row" px={2} width="100%">
        <Grid2 size={{ xs: 4 }}>
          <ParagraphSmall>{t("labels__food_and_beverage_orders_order")}</ParagraphSmall>
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <ParagraphSmall textAlign="center">
            {t("labels__food_and_beverage_orders_location")}
          </ParagraphSmall>
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <ParagraphSmall textAlign="end">
            {t("labels__food_and_beverage_orders_status")}
          </ParagraphSmall>
        </Grid2>
      </Grid2>
      <Grid2 container width="100%">
        {order.map((item) => (
          <Grid2
            size={{ xs: 12 }}
            key={item?.foodAndBeverageId}
            sx={{
              mb: theme.spacing(1.5)
            }}
            alignItems="center"
          >
            <Paper
              elevation={0}
              sx={{
                background: theme.palette.grey[100],
                borderColor: theme.palette.grey[100],
                borderRadius: theme.spacing(1),
                p: 1.5
              }}
              variant="outlined"
            >
              <Grid2 container alignItems="center">
                <Grid2 size={{ xs: 4 }}>
                  <ParagraphSmall>{`${t("labels__food_and_beverage_orders_order")}: ${
                    item?.externalOrderNumber ? item?.externalOrderNumber : item?.foodAndBeverageId
                  }`}</ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <ParagraphSmall textAlign="center">
                    {item?.deliveryLocationType === DeliveryLocationType.InRoom
                      ? t("labels__food_and_beverage_order_item_location_type_IN_ROOM")
                      : `${t(
                          "labels__food_and_beverage_order_item_location_type_TABLE"
                        )} ${item?.deliveryLocation}`}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 4 }} textAlign="end">
                  <Chip
                    size="small"
                    variant="outlined"
                    color={"info"}
                    label={t(`labels__food_and_beverage_order_item_status_${item?.status}`)}
                  />
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
