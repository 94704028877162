import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Button, Caption, ParagraphSmall, useAuth } from "@likemagic-tech/sv-magic-library";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  useTheme
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import {
  BACKOFFICE_ROUTES,
  BACKOFFICE_ROUTES_MULTI_PROPERTY,
  BackofficeRoute
} from "../../../../routes";
import { useNavigateWithPropertyId } from "../../../../hooks/use-navigate-with-propertyId";
import { generateDefaulMessagingTableURLSearchParams } from "../../../../messaging/domain/conversation-constants";
import { HomeWorkOutlined, UnfoldMoreOutlined } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "react-redux";
import {
  selectIsOpenSideNavigation,
  setIsOpenSideNavigation
} from "../../../../slices/dashboard-sidebar";
import { useDispatch } from "../../../../store";
import { truncateText } from "../../../../utils/text";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

const useStyle = makeStyles()((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 14,
    "&.MuiButton-primary": {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white
      }
    },
    "&.MuiIconButton-colorPrimary ": {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white
      }
    },
    "& .MuiButton-endIcon ": {
      width: "100%",
      justifyContent: "flex-end"
    }
  }
}));

const PropertySelector: FC = () => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLElement | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigateWithPropertyId();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { classes: selectClasses } = useStyle();
  const { hasAdminOrOperatorRole } = useAuth();
  const dispatch = useDispatch();
  const {
    allProperties,
    selectedProperty,
    selectedPropertyIds,
    setSelectedPropertyId,
    selectedPropertyPreview,
    isAccountSelected
  } = useProperty();
  const isMobile = useIsMobile();
  const setOpenSideNavigation = (openSidebar: boolean) => {
    dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
  };

  const isAdmin = useMemo(() => {
    return hasAdminOrOperatorRole();
  }, [hasAdminOrOperatorRole]);

  const handleChangeProperty = (propertyId: string): void => {
    setSelectedPropertyId(propertyId);
  };

  useEffect(() => {
    // If the user switches from a single property to a multi-property view, and the page he is on is not a multi-property page, redirect him to the first multi-property page.
    if (
      isAccountSelected &&
      !BACKOFFICE_ROUTES_MULTI_PROPERTY.includes(pathname.split("/")[1] as BackofficeRoute)
    ) {
      // This is defining the page that we are going to redirect the user to.
      navigate(BACKOFFICE_ROUTES.MESSAGING, generateDefaulMessagingTableURLSearchParams());
    }
  }, [allProperties, isAccountSelected, navigate, pathname, selectedPropertyIds]);

  if (!openSideNavigation) {
    return (
      <IconButton
        sx={{
          borderRadius: theme.spacing(0.5),
          py: theme.spacing(0.5),
          px: theme.spacing(1),
          height: 28
        }}
        onClick={() => setOpenSideNavigation(true)}
        disabled={allProperties.length === 1}
      >
        <HomeWorkOutlined
          sx={{
            color: theme.palette.grey[600],
            width: 20,
            height: 20,
            fontSize: 20
          }}
        />
      </IconButton>
    );
  }

  return (
    <Box sx={{ width: "100%" }} ref={anchorRef}>
      <Button
        fullWidth
        variant={isMenuOpen ? "primary" : "ghost"}
        startIcon={
          <HomeWorkOutlined
            sx={{
              cursor: "pointer",
              color: theme.palette.grey[600],
              width: 20,
              height: 20,
              fontSize: 20
            }}
          />
        }
        endIcon={
          <UnfoldMoreOutlined
            sx={{
              cursor: "pointer",
              color: theme.palette.grey[600],
              width: 20,
              height: 20,
              fontSize: 20
            }}
          />
        }
        onClick={() => allProperties.length > 1 && setIsMenuOpen(true)}
        disabled={allProperties.length === 1}
        sx={{
          px: theme.spacing(1.5),
          py: theme.spacing(0.5),
          justifyContent: openSideNavigation ? "flex-start" : "center",
          borderRadius: theme.spacing(0.5),
          whiteSpace: "nowrap",
          height: 28
        }}
        classes={selectClasses}
      >
        {truncateText(selectedPropertyPreview, isMobile ? 25 : undefined)}
      </Button>
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={anchorRef?.current}
        sx={{ mt: 1, boxShadow: theme.shadows[2], borderRadius: theme.spacing(0.5) }}
        MenuListProps={{
          disablePadding: true,
          sx: {
            width: theme.spacing(34),
            outline: "none",
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(0.5)
          }
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: theme.spacing(0.5)
            }
          }
        }}
      >
        <MenuList>
          {isAdmin && (
            <MenuItem
              onClick={() => {
                handleChangeProperty("account");
                setIsMenuOpen(false);
              }}
              sx={{ py: theme.spacing(1.25) }}
            >
              <ListItemText>
                <ParagraphSmall>{t("labels__account")}</ParagraphSmall>
              </ListItemText>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {isAccountSelected && (
                  <CheckIcon fontSize="inherit" sx={{ color: theme.palette.common.black }} />
                )}
              </ListItemIcon>
            </MenuItem>
          )}
          {isAdmin && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: theme.spacing(2),
                py: isAdmin ? theme.spacing(1) : 0
              }}
            >
              <Caption sx={{ color: theme.palette.grey[500] }}>{t("labels__properties")}</Caption>
            </Box>
          )}
          {allProperties.map((property) => (
            <MenuItem
              onClick={() => {
                handleChangeProperty(property.propertyId);
                setIsMenuOpen(false);
              }}
              sx={{ py: theme.spacing(1.25) }}
              key={property.propertyId}
            >
              <ListItemText>
                <ParagraphSmall>{property?.details?.name}</ParagraphSmall>
              </ListItemText>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {selectedPropertyIds.length === 1 &&
                  selectedProperty.propertyId === property.propertyId && (
                    <CheckIcon fontSize="inherit" sx={{ color: theme.palette.common.black }} />
                  )}
              </ListItemIcon>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default PropertySelector;
