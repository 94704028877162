import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { Grid2, styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { closedMixin, openDrawerWidth, openedMixin } from "../dashboard-layout";
import { useTheme } from "@mui/material/styles";
import { selectIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useSelector } from "../../../store";
import MobileSidebar from "./mobile-sidebar";
import { useIsMobile } from "src/hooks/use-is-mobile";
import Logo from "../../logo/logo";
import DashboardMenuPopover from "../dashboard-menu-popover";
import { ReleaseNotes } from "../../../features/release-notes/release-notes";
import PropertySelector from "./property-selector/property-selector";
import { DashboardSidebarFooter } from "./dashboard-sidebar-footer";
import SidebarSections from "./sidebar-sections";
import React from "react";
import { DashboardSidebarCollapseButton } from "./dashboard-sidebar-collapse-button";
import { useNavigate } from "react-router-dom";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: openDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const DashboardSidebar = () => {
  const { spacing } = useTheme();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const isMobile = useIsMobile();
  const { features } = usePropertyConfig({});
  const navigate = useNavigate();

  return !isMobile ? (
    <Drawer
      variant="permanent"
      anchor="left"
      open={openSideNavigation}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          borderRight: "none",
          px: spacing(2),
          py: spacing(2),
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            "& .MuiIconButton-root": {
              opacity: 1
            }
          }
        }
      }}
    >
      <Grid2 container sx={{ gap: spacing(3), width: "100%" }}>
        <Grid2
          container
          sx={{
            gap: spacing(3),
            width: "100%"
          }}
        >
          <Grid2
            container
            sx={{
              flexDirection: "column",
              gap: spacing(3),
              width: "100%"
            }}
          >
            <Grid2
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                cursor: "pointer"
              }}
              onClick={() => navigate("/")}
            >
              <Logo
                isDark
                collapsed={!openSideNavigation}
                height={features?.tfeFeatureEnabled ? "20" : "28"}
                isTfe={features?.tfeFeatureEnabled}
              />
            </Grid2>
          </Grid2>
          <PropertySelector />
          <SidebarSections />
        </Grid2>
      </Grid2>
      <Grid2
        container
        sx={{
          flexDirection: "column",
          gap: spacing(1),
          width: "100%"
        }}
      >
        <DashboardSidebarCollapseButton />
        <ReleaseNotes />
        <DashboardMenuPopover showName={openSideNavigation} showMoreButton={openSideNavigation} />
        {features?.tfeFeatureEnabled && <DashboardSidebarFooter />}
      </Grid2>
    </Drawer>
  ) : (
    <MobileSidebar />
  );
};

export default DashboardSidebar;
