import * as React from "react";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "../../store";
import { selectAllUnreadMessageCount } from "../../slices/unread-message-count.slice";
import useRequestNotificationPermission from "../../hooks/use-request-notification-presmission";

interface NavigatorWithAppBadge extends Navigator {
  setAppBadge?: (count: number) => Promise<void>;
  clearAppBadge?: () => Promise<void>;
}

export const HelmetWrapper: FC<{ title: string }> = ({ title }) => {
  const noOfAllUnreadMessages = useSelector(selectAllUnreadMessageCount);
  const prefix = noOfAllUnreadMessages ? `(${noOfAllUnreadMessages}) ` : "";

  useRequestNotificationPermission();

  useEffect(() => {
    const navigatorExt = navigator as NavigatorWithAppBadge;

    if (navigatorExt.setAppBadge && navigatorExt.clearAppBadge) {
      if (noOfAllUnreadMessages > 0) {
        navigatorExt.setAppBadge(noOfAllUnreadMessages).catch(console.error);
      } else {
        navigatorExt.clearAppBadge().catch(console.error);
      }
    }
  }, [noOfAllUnreadMessages]);

  return (
    <Helmet>
      <title>
        {prefix}
        {title}
      </title>
    </Helmet>
  );
};
