import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";

interface PersonCardInfoTitleProps {
  title: string;
  content?: ReactElement;
}

export const PersonCardInfoTitle: FC<PersonCardInfoTitleProps> = ({ title, content }) => {
  const theme = useTheme();
  return (
    <Grid2 px={3} pt={1}>
      <Grid2 container direction="row" alignItems="center" spacing={1}>
        <Grid2>
          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>{title}</ParagraphSmall>
        </Grid2>
        <Grid2>{content}</Grid2>
      </Grid2>
    </Grid2>
  );
};
