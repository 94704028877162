import { PrepareHeaders } from "@rtk-query/graphql-request-base-query/src/GraphqlBaseQueryTypes";
import type { ClientError } from "graphql-request";
import { ApiError } from "@likemagic-tech/sv-magic-library";

export const prepareHeaders: PrepareHeaders = async (headers, api) => {
  // @ts-ignore
  const keycloak: any | undefined = api?.extra?.keycloak;

  try {
    if (keycloak?.authenticated && keycloak?.isTokenExpired()) {
      await keycloak.updateToken(5);
    }

    if (keycloak?.authenticated && keycloak?.token) {
      headers.delete("Authorization");
      headers.set("Authorization", `Bearer ${keycloak.token}`);
    }
  } catch (e) {
    keycloak.login();
  }
  return headers;
};
const handleErrorStatus = (errorCode: string) => {
  switch (errorCode) {
    case "errors_unauthorized":
      return 401;
    default:
      return 500;
  }
};

export const customErrors = (args: ClientError) => {
  const { name, stack, response } = args;

  const { errorCode = 500 } = response?.errors?.length ? response?.errors[0]?.extensions : {};
  const { message = "" } = response?.errors?.length ? response?.errors[0] : {};

  return new ApiError({
    error: name,
    id: errorCode,
    message: message,
    requestId: "",
    status: handleErrorStatus(errorCode),
    timestamp: new Date().toISOString(),
    trace: stack ?? ""
  }).toJSON();
};
