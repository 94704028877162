import { Divider, Grid2, useTheme } from "@mui/material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import Logo from "../../logo/logo";
import { useSelector } from "../../../store";
import { selectIsOpenSideNavigation } from "../../../slices/dashboard-sidebar";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";

export const DashboardSidebarFooter = () => {
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { palette, spacing } = useTheme();
  const { t } = useTranslationWrapper();

  return (
    <>
      <Divider sx={{ width: "100%" }} />
      <Grid2
        container
        sx={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "nowrap",
          px: openSideNavigation ? 0 : spacing(1)
        }}
      >
        {openSideNavigation && (
          <ParagraphSmall color={palette.text.secondary} fontSize={10}>
            {t("labels__powered_by")}
          </ParagraphSmall>
        )}
        <Logo isDark collapsed={!openSideNavigation} height="20" />
      </Grid2>
    </>
  );
};
