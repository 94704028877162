import { FC, useMemo, useState } from "react";
import { ServiceModalsProps } from "./types";
import { PricePreview } from "src/components/price-preview/price-preview";
import { Box, Divider, Grid2 } from "@mui/material";
import { Chip, formatToIsoDate, Input, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Dialog } from "./dialog";
import { Granularity } from "src/domain/service";
import { makeStyles } from "tss-react/mui";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { multiplyPrices } from "src/utils/price";
import { emptyPrice } from "src/domain/price";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles()(() => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  }
}));

export const InputDialog: FC<ServiceModalsProps> = ({
  service,
  reservation,
  onClose,
  onChange
}) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();

  const [licensePlate, setLicensePlate] = useState(
    reservation?.primaryGuest.licensePlate ?? undefined
  );

  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: any) => new Date(dateAndCount.date)
  );

  const quantity = useMemo(() => {
    if (service?.granularity === Granularity.WHOLE_STAY_PAY_ONCE) {
      return 1;
    } else {
      return availableDays.length;
    }
  }, [service, availableDays.length]);

  const handleChange = () => {
    if (!licensePlate) {
      return;
    }
    const values = availableDays.map((date) => ({
      date: formatToIsoDate(date),
      count: 1
    }));
    onChange({ values, licensePlate });
  };

  return (
    <Dialog
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      title={t("labels__title_input_parking_dialog")}
      content={
        <Box className={classes.dialogContentContainer}>
          <Paragraph textAlign={"center"} pb={1}>
            {t("labels__input_parking_dialog")}
          </Paragraph>
          <Grid2
            container
            direction="column"
            spacing={2}
            p={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid2 size={{ xs: 12 }}>
              <Input
                onChange={(e) => setLicensePlate(e.target.value)}
                variant={"outlined"}
                value={licensePlate}
                label={t("labels__license_plate")}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Chip
                color="info"
                label={t("labels__license_plate_services_info")}
                leftIcon={<InfoOutlinedIcon />}
                sx={{
                  p: 3,
                  display: "flex",
                  width: "100%",
                  "& .MuiChip-label": {
                    overflow: "visible"
                  }
                }}
              />
            </Grid2>
          </Grid2>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              px: 2.5,
              py: 2
            }}
          >
            <PricePreview price={multiplyPrices(service?.price ?? emptyPrice(), quantity)} />
          </Box>
        </Box>
      }
      buttonLabel={t("labels__wizard_confirm")}
    />
  );
};
