import * as Types from "../generated/graphql";

export type ServiceContractFragment = {
  __typename?: "ServiceContract";
  active?: boolean | null;
  chargeMode?: Types.ChargeMode | null;
  chargeUnit?: Types.ChargeUnit | null;
  description?: string | null;
  displayName?: string | null;
  id?: string | null;
  included?: boolean | null;
  name?: string | null;
  pmsCategoryId?: string | null;
  pmsId?: string | null;
  pmsUpdatedAt?: any | null;
  price?: {
    __typename?: "Price";
    currency?: string | null;
    grossPrice?: any | null;
    netPrice?: any | null;
    taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null } | null> | null;
  } | null;
};

export const ServiceContractFragmentDoc = `
    fragment ServiceContract on ServiceContract {
  active
  chargeMode
  chargeUnit
  description
  displayName
  id
  included
  name
  pmsCategoryId
  pmsId
  pmsUpdatedAt
  price {
    currency
    grossPrice
    netPrice
    taxes {
      amount
      code
    }
  }
}
    `;
