import { Grid2 } from "@mui/material";
import { FC, useState } from "react";
import { regionNames } from "./reservation-details-guest-insight-utils";
import { Button, formatDate, Input, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Person } from "src/domain/person";
import { getI18nSelectedLanguage } from "src/utils/language";
import { useProperty } from "src/hooks/use-property";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { ConfirmIdentityModal } from "src/components/dashboard/identity/confirm-identity-modal";
import { clearMagicFiles } from "src/slices/magic-files.slice";
import { useDispatch } from "src/store";

interface PersonCardInfoLegalInfoProps {
  guest: Person;
  reservation: ReservationDetailsDTO;
}

export const PersonCardInfoLegalInfo: FC<PersonCardInfoLegalInfoProps> = ({
  guest,
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const language = getI18nSelectedLanguage();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const dispatch = useDispatch();
  const [isConfirmIdentityModalOpen, setIsConfirmIdentityModalOpen] = useState(false);

  return (
    <Grid2 container direction="row" spacing={3} px={3}>
      <Grid2 size={{ xs: 12 }}>
        <Grid2 container direction="row" spacing={2}>
          <Grid2 size={{ xs: 6, md: 4 }}>
            <Input
              disabled
              variant="outlined"
              forceShrink
              value={
                guest.nationalityCountryCode ? regionNames.of(guest.nationalityCountryCode) : ""
              }
              label={t("labels__nationality")}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, md: 4 }}>
            <Input
              disabled
              variant="outlined"
              forceShrink
              value={formatDate(guest.birthdate, language, selectedProperty.details.timeZone)}
              label={t("labels__date_of_birth")}
              slotProps={{
                inputLabel: { shrink: true }
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, md: 4 }}>
            <Input
              disabled
              variant="outlined"
              forceShrink
              value={guest.identificationNumber}
              label={t("labels__document_number")}
              slotProps={{
                inputLabel: { shrink: true }
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, md: 4 }} display="flex">
            <Button
              size="large"
              variant="secondary"
              onClick={() => setIsConfirmIdentityModalOpen(true)}
              sx={{ heigh: "100%" }}
              fullWidth
            >
              {t("labels__show_identification")}
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <ConfirmIdentityModal
        reservation={reservation}
        isOpen={isConfirmIdentityModalOpen}
        onClose={() => {
          dispatch(clearMagicFiles());
          setIsConfirmIdentityModalOpen(false);
        }}
        propertyId={selectedProperty.propertyId}
        documentUploadEnabled={features?.documentsUploadEnabled ?? false}
      />
    </Grid2>
  );
};
