import * as Types from "../generated/graphql";

import { ActorFragmentDoc } from "./Actor.generated";
export type TaskFragment = {
  __typename?: "TaskDto";
  id: number;
  title: string;
  description?: string | null;
  metadata?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: Types.TaskType;
  due?: any | null;
  status: Types.Status;
  priority: Types.Priority;
  parentId?: number | null;
  pmsId?: string | null;
  attachments?: Array<{
    __typename?: "Attachment";
    uuid: string;
    contentType: string;
    fileName: string;
    originalFileName: string;
  }> | null;
  assignee?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: Types.ActorType;
    displayName?: string | null;
  } | null;
  assigneeGroup?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: Types.ActorType;
    displayName?: string | null;
  } | null;
  reporter: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: Types.ActorType;
    displayName?: string | null;
  };
  links?: {
    __typename?: "Links";
    pmsReservationIds?: Array<string> | null;
    pmsDepartingReservationId?: string | null;
    pmsArrivingReservationId?: string | null;
    pmsUnitIds?: Array<string> | null;
    reservations?: Array<{
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    }> | null;
    departingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
      departure: any;
    } | null;
    arrivingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    } | null;
    units?: Array<{
      __typename?: "Unit";
      pmsUnitId: string;
      name: string;
      floor?: string | null;
    }> | null;
  } | null;
  subtasks?: Array<{
    __typename?: "TaskDto";
    id: number;
    title: string;
    parentId?: number | null;
  }> | null;
  parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
};

export const TaskFragmentDoc = `
    fragment Task on TaskDto {
  id
  title
  description
  metadata
  createdAt
  updatedAt
  type
  attachments {
    uuid
    contentType
    fileName
    originalFileName
  }
  assignee {
    ...Actor
  }
  assigneeGroup {
    ...Actor
  }
  reporter {
    ...Actor
  }
  reporter {
    ...Actor
  }
  due
  links {
    pmsReservationIds
    pmsDepartingReservationId
    pmsArrivingReservationId
    pmsUnitIds
    reservations {
      displayableReservationId
      pmsReservationId
      adultsAmount
      childrenAmount
    }
    departingReservation {
      displayableReservationId
      pmsReservationId
      adultsAmount
      childrenAmount
      departure
    }
    arrivingReservation {
      displayableReservationId
      pmsReservationId
      adultsAmount
      childrenAmount
    }
    units {
      pmsUnitId
      name
      floor
    }
  }
  status
  priority
  subtasks {
    id
    title
    parentId
  }
  parentId
  parent {
    id
    title
  }
  pmsId
}
    `;
