import { useEffect } from "react";

interface NavigatorWithStandalone extends Navigator {
  standalone?: boolean;
}

const useRequestNotificationPermission = () => {
  useEffect(() => {
    const isPWA =
      window.matchMedia("(display-mode: standalone)").matches ||
      (navigator as NavigatorWithStandalone).standalone;

    if (isPWA && Notification.permission === "default") {
      Notification.requestPermission().catch(console.error);
    }
  }, []);
};

export default useRequestNotificationPermission;
