export enum MagicFileType {
  SIGNATURE_DOCUMENT = "SIGNATURE_DOCUMENT",
  IDENTIFICATION_DOCUMENT = "IDENTIFICATION_DOCUMENT",
  INVOICE = "INVOICE",
  ATTACHMENTS = "ATTACHMENTS"
}

export interface MagicFileData {
  fileName: string;
  magicFileType: MagicFileType;
  fileSrc: string;
  contentType: string;
}

export interface MagicFile {
  fileName: string;
  contentType: string;
  metaData: {
    ownerId: string;
    magicFileType: MagicFileType;
  };
}
