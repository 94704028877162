import React, { FC, useMemo, useState } from "react";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import {
  AdditionalServiceWithPrice,
  DateAndCount,
  Granularity,
  InteractionType
} from "../../../../domain/service";
import { calculateInteractionType } from "../../../../utils/services";
import { AdditionalServicesAvailabilityResponse } from "../../../../domain/additional-services-availability";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { ServiceModalsHandler } from "../../manual-checkin/components/manual-checkin-step-services/components/services-modals-handler";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { useAdditionalServicesAvailability } from "../../../../hooks/use-additional-services-availability";
import { multiplyPrices } from "../../../../utils/price";
import { emptyPrice } from "../../../../domain/price";
import { useUpdatePrimaryGuest } from "src/hooks/use-update-primary-guest";
import { transformPrimaryGuestToUpdateUserInput } from "src/graphql/transform/transform-update-user-input";

interface AdditionalServicesAutocompleteProps {
  reservationDetails: ReservationDetailsDTO;
  setAdditionalServices: (arg: AdditionalServiceWithPrice[]) => void;
  additionalServices: AdditionalServiceWithPrice[];
}

export const AdditionalServicesAutocomplete: FC<AdditionalServicesAutocompleteProps> = ({
  setAdditionalServices,
  additionalServices,
  reservationDetails
}) => {
  const [currentlySelectedAdditionalService, setCurrentlySelectedAdditionalService] = useState<
    AdditionalServicesAvailabilityResponse | undefined
  >(undefined);
  const { t } = useTranslationWrapper();
  const { data: additionalServicesOptionsData, isLoading } = useAdditionalServicesAvailability({
    reservationId: reservationDetails.id ?? ""
  });
  const [updatePrimaryGuest] = useUpdatePrimaryGuest();

  const handleAutoCompleteSelect = async (value: AutocompleteOption) => {
    const selectedService = additionalServicesOptionsData?.find(
      (service) => service.serviceId === value.id
    );

    if (
      selectedService &&
      calculateInteractionType(selectedService) === InteractionType.BOOLEAN &&
      selectedService
    ) {
      setAdditionalServices([
        ...additionalServices,
        {
          ...selectedService,
          serviceId: selectedService.serviceId,
          code: selectedService.code ?? "",
          dates: selectedService.bookableDates?.map((value) => value.date) ?? [],
          price: selectedService.price ?? { amount: 0, currency: "" },
          quantity: 1
        }
      ]);
    } else {
      setCurrentlySelectedAdditionalService(selectedService);
    }
  };

  const additionalServicesAutocompleteOptions = useMemo(() => {
    return (
      additionalServicesOptionsData
        ?.map((service) => {
          return {
            id: service.serviceId,
            label: service.name
          } as AutocompleteOption;
        })
        .filter((service) => {
          return !additionalServices?.find(
            (additionalService) => additionalService.serviceId === service.id
          );
        }) ?? []
    );
  }, [additionalServices, additionalServicesOptionsData]);

  const onChange = async (e: { values: DateAndCount[]; licensePlate?: string }) => {
    const sumCount = e.values.map((service) => service.count).reduce((a, b) => a + b, 0);

    const service = additionalServicesOptionsData?.find(
      (service) => service.serviceId === currentlySelectedAdditionalService?.serviceId
    );

    if (e.values.length && service?.price) {
      setAdditionalServices([
        ...additionalServices,
        {
          ...service,
          price: multiplyPrices(service.price ?? emptyPrice(), sumCount),
          quantity:
            // if it's a service that can be booked for any day, we only want to show and send the count per day, not the sum of all days
            service.granularity === Granularity.ANY_DAY ||
            service.granularity === Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY
              ? e.values[0].count
              : sumCount,
          dates: e.values.map((value: DateAndCount) => value.date)
        }
      ]);
    }
    if (
      reservationDetails?.primaryGuest &&
      e.licensePlate !== reservationDetails?.primaryGuest.licensePlate
    ) {
      updatePrimaryGuest({
        reservationId: reservationDetails?.id ?? "",
        person: {
          ...transformPrimaryGuestToUpdateUserInput(reservationDetails?.primaryGuest),
          licensePlate: e.licensePlate
        }
      });
    }
    setCurrentlySelectedAdditionalService(undefined);
  };

  return (
    <>
      <AutocompleteComponent
        key={currentlySelectedAdditionalService?.serviceId}
        options={additionalServicesAutocompleteOptions}
        onSelected={handleAutoCompleteSelect}
        label={t("buttons__wizard_add_service")}
        value={null}
        loading={isLoading}
      />
      {!!currentlySelectedAdditionalService && (
        <ServiceModalsHandler
          onClose={() => setCurrentlySelectedAdditionalService(undefined)}
          onChange={onChange}
          reservation={reservationDetails}
          service={currentlySelectedAdditionalService}
        />
      )}
    </>
  );
};
