import { Box, Drawer, Grid2, useTheme } from "@mui/material";
import { selectIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useSelector } from "../../../store";
import PropertySelector from "./property-selector/property-selector";
import SidebarSections from "./sidebar-sections";
import Logo from "../../logo/logo";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { DashboardSidebarFooter } from "./dashboard-sidebar-footer";

const mobileDrawerWidth = 288;

const MobileSidebar = () => {
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { spacing, palette } = useTheme();
  const topSpacing = spacing(7);
  const { features } = usePropertyConfig({});

  return openSideNavigation ? (
    <Drawer
      variant="permanent"
      anchor="left"
      open={openSideNavigation}
      PaperProps={{
        sx: {
          top: topSpacing,
          width: mobileDrawerWidth,
          height: `calc(100% - ${topSpacing})`,
          pt: spacing(2),
          px: spacing(2),
          backgroundColor: palette.background.default,
          borderTop: `1px solid ${palette.grey[300]}`,
          zIndex: 1200 + 5 //5 is a max number of detail drawers that we can support
        }
      }}
    >
      <Grid2 container height="100%">
        <Grid2 sx={{ width: "100%", display: "flex", flexDirection: "column", gap: spacing(3) }}>
          <PropertySelector />
          <SidebarSections />
        </Grid2>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: spacing(2),
            mt: spacing(3),
            pb: spacing(3)
          }}
        >
          <Logo
            isDark
            height={features?.tfeFeatureEnabled ? "20" : "28"}
            isTfe={features?.tfeFeatureEnabled}
          />
          {features?.tfeFeatureEnabled && <DashboardSidebarFooter />}
        </Box>
      </Grid2>
    </Drawer>
  ) : (
    <></>
  );
};

export default MobileSidebar;
