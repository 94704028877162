import * as React from "react";
import { FC } from "react";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import {
  getLastStuckGuestJourneyPage,
  getPercentageOfGJProgress
} from "../../../../utils/reservation-utils";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { GuestFlowCheckpoint, useGuestPreCheckinFlow } from "@likemagic-tech/sv-magic-library";
import { useFeatureFlags } from "../../../../hooks/use-configuration";

interface PreCheckinProgressProps {
  lastConfirmedPage?: GuestFlowCheckpoint;
  completed: boolean;
  propertyId: string;
}

export const PreCheckinProgress: FC<PreCheckinProgressProps> = ({
  lastConfirmedPage,
  completed,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const features = useFeatureFlags();

  const { guestFlowPages } = useGuestPreCheckinFlow({
    upsellUnitGroupEnabled: !!features?.upsellUnitGroupEnabled,
    propertyId: propertyId
  });

  return (
    <Tooltip
      title={t(
        `labels__step_data_${
          getLastStuckGuestJourneyPage(guestFlowPages, lastConfirmedPage ?? guestFlowPages[0]) ||
          "NOT_STARTED"
        }`
      )}
    >
      <Box pr={1}>
        <LinearProgress
          color="success"
          variant="buffer"
          valueBuffer={100}
          value={getPercentageOfGJProgress(
            lastConfirmedPage ?? guestFlowPages[0],
            completed,
            guestFlowPages
          )}
        />
      </Box>
    </Tooltip>
  );
};
