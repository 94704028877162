import * as Types from "../generated/graphql";

import { ServiceContractFragmentDoc } from "../fragments/ServiceContract.generated";
import { UnitGroupContractFragmentDoc } from "../fragments/UnitGroupContract.generated";
import { TaskConfigFragmentDoc } from "../fragments/TaskConfig.generated";
import { tenantconfigApi } from "../tenantconfig.api";
export type GetServiceConfigsQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetServiceConfigsQuery = {
  __typename?: "Query";
  GetServiceConfigs: Array<
    | {
        __typename?: "AddOnServiceConfig";
        minimumBefore?: string | null;
        maximumBefore?: string | null;
        maximum?: number | null;
        bookableDays?: Array<Types.BookableDay | null> | null;
        bookingChannels?: Array<string | null> | null;
        reservationStatuses?: Array<Types.ReservationStatus | null> | null;
        visibilities?: Array<Types.Visibility | null> | null;
        payAtCheckout?: boolean | null;
        addOnType: Types.AddOnType;
        canBookMultiple?: boolean | null;
        bookableOnDepartureDay?: boolean | null;
        bookOnExtendStay?: boolean | null;
        availableAfterMidnight?: string | null;
        granularity?: Types.ServiceConfigGranularity | null;
        defaultTargetAudience?: Types.DefaultTargetAudience | null;
        tags?: Array<Types.ServiceTag | null> | null;
        defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
        postOnNextDay?: boolean | null;
        serviceConfigHash: number;
        type: Types.ServiceConfigType;
        serviceId: string;
        unitGroupIds: Array<string | null>;
        service?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId?: string | null;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
        unitGroups: Array<{
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId?: string | null;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null>;
        task?: {
          __typename?: "TaskConfig";
          housekeepingMainTask?: boolean | null;
          assigneeGroup?: string | null;
          createMultipleTasks?: boolean | null;
          dueDuration?: string | null;
          dueDurationType?: Types.DueDurationType | null;
          granularity?: Types.TaskGranularity | null;
          priority?: Types.Priority | null;
        } | null;
      }
    | {
        __typename?: "AdditionalCleaningServiceConfig";
        minimumBefore?: string | null;
        maximumBefore?: string | null;
        maximum?: number | null;
        bookableDays?: Array<Types.BookableDay | null> | null;
        bookingChannels?: Array<string | null> | null;
        reservationStatuses?: Array<Types.ReservationStatus | null> | null;
        visibilities?: Array<Types.Visibility | null> | null;
        payAtCheckout?: boolean | null;
        granularity?: Types.ServiceConfigGranularity | null;
        defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
        serviceConfigHash: number;
        type: Types.ServiceConfigType;
        serviceId: string;
        unitGroupIds: Array<string | null>;
        service?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId?: string | null;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
        unitGroups: Array<{
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId?: string | null;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null>;
        task?: {
          __typename?: "TaskConfig";
          housekeepingMainTask?: boolean | null;
          assigneeGroup?: string | null;
          createMultipleTasks?: boolean | null;
          dueDuration?: string | null;
          dueDurationType?: Types.DueDurationType | null;
          granularity?: Types.TaskGranularity | null;
          priority?: Types.Priority | null;
        } | null;
      }
    | {
        __typename?: "EarlyCheckinServiceConfig";
        minimumBefore?: string | null;
        maximumBefore?: string | null;
        maximum?: number | null;
        bookableDays?: Array<Types.BookableDay | null> | null;
        bookingChannels?: Array<string | null> | null;
        reservationStatuses?: Array<Types.ReservationStatus | null> | null;
        visibilities?: Array<Types.Visibility | null> | null;
        payAtCheckout?: boolean | null;
        minimumCheckinTime?: any | null;
        serviceConfigHash: number;
        type: Types.ServiceConfigType;
        serviceId: string;
        unitGroupIds: Array<string | null>;
        service?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId?: string | null;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
        unitGroups: Array<{
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId?: string | null;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null>;
        task?: {
          __typename?: "TaskConfig";
          housekeepingMainTask?: boolean | null;
          assigneeGroup?: string | null;
          createMultipleTasks?: boolean | null;
          dueDuration?: string | null;
          dueDurationType?: Types.DueDurationType | null;
          granularity?: Types.TaskGranularity | null;
          priority?: Types.Priority | null;
        } | null;
      }
    | {
        __typename?: "FreeCleaningServiceConfig";
        minimumNights?: number | null;
        interval?: any | null;
        weekendRule?: Types.WeekendRule | null;
        distributionRule?: Types.DistributionRule | null;
        serviceConfigHash: number;
        type: Types.ServiceConfigType;
        serviceId: string;
        unitGroupIds: Array<string | null>;
        service?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId?: string | null;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
        unitGroups: Array<{
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId?: string | null;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null>;
        task?: {
          __typename?: "TaskConfig";
          housekeepingMainTask?: boolean | null;
          assigneeGroup?: string | null;
          createMultipleTasks?: boolean | null;
          dueDuration?: string | null;
          dueDurationType?: Types.DueDurationType | null;
          granularity?: Types.TaskGranularity | null;
          priority?: Types.Priority | null;
        } | null;
      }
    | {
        __typename?: "LateCheckoutServiceConfig";
        minimumBefore?: string | null;
        maximumBefore?: string | null;
        maximum?: number | null;
        bookableDays?: Array<Types.BookableDay | null> | null;
        bookingChannels?: Array<string | null> | null;
        reservationStatuses?: Array<Types.ReservationStatus | null> | null;
        visibilities?: Array<Types.Visibility | null> | null;
        payAtCheckout?: boolean | null;
        maximumCheckoutTime?: any | null;
        serviceConfigHash: number;
        type: Types.ServiceConfigType;
        serviceId: string;
        unitGroupIds: Array<string | null>;
        service?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId?: string | null;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
        unitGroups: Array<{
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId?: string | null;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null>;
        task?: {
          __typename?: "TaskConfig";
          housekeepingMainTask?: boolean | null;
          assigneeGroup?: string | null;
          createMultipleTasks?: boolean | null;
          dueDuration?: string | null;
          dueDurationType?: Types.DueDurationType | null;
          granularity?: Types.TaskGranularity | null;
          priority?: Types.Priority | null;
        } | null;
      }
    | null
  >;
};

export const GetServiceConfigsDocument = `
    query GetServiceConfigs($pmsPropertyId: String!) {
  GetServiceConfigs(pmsPropertyId: $pmsPropertyId) {
    serviceConfigHash
    type
    serviceId
    service {
      ...ServiceContract
    }
    unitGroupIds
    unitGroups {
      ...UnitGroupContract
    }
    task {
      ...TaskConfig
    }
    ... on BaseServiceConfig {
      minimumBefore
      maximumBefore
      maximum
      bookableDays
      bookingChannels
      reservationStatuses
      visibilities
      payAtCheckout
    }
    ... on FreeCleaningServiceConfig {
      minimumNights
      interval
      weekendRule
      distributionRule
    }
    ... on AdditionalCleaningServiceConfig {
      granularity
      defaultDatePickerSelection
    }
    ... on AddOnServiceConfig {
      addOnType
      canBookMultiple
      bookableOnDepartureDay
      bookOnExtendStay
      availableAfterMidnight
      granularity
      defaultTargetAudience
      tags
      defaultDatePickerSelection
      postOnNextDay
    }
    ... on EarlyCheckinServiceConfig {
      minimumCheckinTime
    }
    ... on LateCheckoutServiceConfig {
      maximumCheckoutTime
    }
  }
}
    ${ServiceContractFragmentDoc}
${UnitGroupContractFragmentDoc}
${TaskConfigFragmentDoc}`;

const injectedRtkApi = tenantconfigApi.injectEndpoints({
  endpoints: (build) => ({
    GetServiceConfigs: build.query<GetServiceConfigsQuery, GetServiceConfigsQueryVariables>({
      query: (variables) => ({ document: GetServiceConfigsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetServiceConfigsQuery, useLazyGetServiceConfigsQuery } = injectedRtkApi;
