import React, { FC } from "react";
import { Price, ServiceConfig } from "../../../graphql-tenantconfig/generated/graphql";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";

interface TableRowProps {
  data: ServiceConfig;
}

function vat(price?: Price): string {
  if (price && price.netPrice && price.grossPrice) {
    try {
      return new Intl.NumberFormat(navigator.language, {
        style: "percent"
      }).format((price.grossPrice - price.netPrice) / price.netPrice);
    } catch (e) {
      return "";
    }
  }
  return "";
}

function price(price?: Price): string {
  if (price && price.grossPrice && price.currency) {
    try {
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: price.currency
      }).format(price.grossPrice);
    } catch (e) {
      return "";
    }
  }
  return "";
}

const AdditionalServicesTableRow: FC<TableRowProps> = ({ data }) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <TableRow key={data.serviceConfigHash}>
        <TableCell sx={{ paddingLeft: spacing(1), borderBottomWidth: open ? 0 : 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ marginRight: spacing(1) }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <b>{data?.service?.displayName || data?.serviceId}</b>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.service?.pmsId || data.serviceId}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.service?.price && price(data?.service?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.service?.price && vat(data?.service?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.service?.chargeMode && (
            <>{t("labels__settings_service_config_charge_mode_" + data?.service?.chargeMode)}</>
          )}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.service?.chargeUnit && (
            <>{t("labels__settings_service_config_charge_unit_" + data?.service?.chargeUnit)}</>
          )}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data?.type && <>{t("labels__settings_service_config_type_" + data.type)}</>}
        </TableCell>
      </TableRow>
      {data.unitGroups.map((unitGroup, index) => (
        <TableRow key={data.serviceConfigHash + "-" + unitGroup?.pmsId}>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottomWidth: open && index === data.unitGroups.length - 1 ? 1 : 0
            }}
            sx={{ paddingLeft: spacing(6.5) }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                  paddingBottom: spacing(index === data.unitGroups.length - 1 ? 2.25 : 1.25)
                }}
              >
                {unitGroup?.name}
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottomWidth: open && index === data.unitGroups.length - 1 ? 1 : 0
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                  paddingBottom: spacing(index === data.unitGroups.length - 1 ? 2.25 : 1.25)
                }}
              >
                {unitGroup?.pmsId}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default AdditionalServicesTableRow;
