import {
  BalanceFilterValue,
  BookingChannelFilterValue,
  FlowStateFilterValue,
  GuaranteeTypeFilterValue,
  RecurringFilterValue,
  ReservationStatus as ReservationStatusV2
} from "../generated/graphql";
import { ReservationStatus } from "src/domain/reservation-status";
import {
  AdditionalFiltersEnum,
  BalanceStatusEnum,
  ChannelCodeEnum,
  GuaranteeType as GuaranteeTypeV1,
  GuestFlowStatusEnum,
  RecurringGuestEnum
} from "src/features/reservations/domain/enums";
import { ReservationConditionsResponse } from "src/store/endpoints/reservation-table.endpoints";
import { ConditionCountsQuery } from "../queries/GetConditionCounts.generated";

export const transformReservationSortingFiltersCommonToV2ReservationSortingFilter = (
  orderBy: string,
  order: string
) => {
  return orderBy + "," + order;
};

export const transformReservationConditionsCountV2ToCommon = (
  filter?: ConditionCountsQuery
): ReservationConditionsResponse => {
  const { ConditionCounts } = filter || {};
  return ConditionCounts;
};

export const transformValueV2ByTheName = (name: string, value: string) => {
  switch (name) {
    case AdditionalFiltersEnum.RESERVATION_STATUS:
      return value
        .split(",")
        .map((condition: string) => {
          return transformReservationConditionCommonToV2(condition as ReservationStatus);
        })
        .join(",");
    case AdditionalFiltersEnum.FLOW_STATE:
      return value
        .split(",")
        .map((status: string) => {
          return transformReservationFlowStatusCommonToV2(status as GuestFlowStatusEnum);
        })
        .join(",");
    case AdditionalFiltersEnum.CHANNEL_CODE:
      return value
        .split(",")
        .map((channelCode: string) => {
          return transformReservationChannelCodeCommonToV2(channelCode as ChannelCodeEnum);
        })
        .join(",");
    case AdditionalFiltersEnum.BALANCE_STATUS:
      return value
        .split(",")
        .map((balanceStatus: string) => {
          return transformReservationBalanceStatusCommonToV2(balanceStatus as BalanceStatusEnum);
        })
        .join(",");
    case AdditionalFiltersEnum.RECURRING_GUEST:
      return value
        .split(",")
        .map((recurringGuest: string) => {
          return transformReservationRecurringFilterValueCommonToV2(
            recurringGuest as RecurringGuestEnum
          );
        })
        .join(",");
    case AdditionalFiltersEnum.GUARANTEE_TYPE:
      return value
        .split(",")
        .map((guaranteeType: string) => {
          return transformReservationGuaranteeTypeFilterValueCommonToV2(
            guaranteeType as GuaranteeTypeV1
          );
        })
        .join(",");
    default:
      return value;
  }
};

const transformReservationConditionCommonToV2 = (
  condition: ReservationStatus
): ReservationStatusV2 => {
  switch (condition) {
    case ReservationStatus.CONFIRMED:
      return ReservationStatusV2.Confirmed;
    case ReservationStatus.CHECKED_OUT:
      return ReservationStatusV2.CheckedOut;
    case ReservationStatus.IN_HOUSE:
      return ReservationStatusV2.InHouse;
    case ReservationStatus.NO_SHOW:
      return ReservationStatusV2.NoShow;
    case ReservationStatus.DELETED:
      return ReservationStatusV2.Cancelled;
  }
};

const transformReservationFlowStatusCommonToV2 = (
  status: GuestFlowStatusEnum
): FlowStateFilterValue => {
  switch (status) {
    case GuestFlowStatusEnum.COMPLETED:
      return FlowStateFilterValue.Completed;
    case GuestFlowStatusEnum.STARTED:
      return FlowStateFilterValue.Started;
    case GuestFlowStatusEnum.NOT_STARTED:
      return FlowStateFilterValue.NotStarted;
  }
};

const transformReservationChannelCodeCommonToV2 = (
  channel: ChannelCodeEnum
): BookingChannelFilterValue => {
  switch (channel) {
    case ChannelCodeEnum.BOOKING:
      return BookingChannelFilterValue.Booking;
    case ChannelCodeEnum.EXPEDIA:
      return BookingChannelFilterValue.Expedia;
    case ChannelCodeEnum.AIRBNB:
      return BookingChannelFilterValue.Airbnb;
    case ChannelCodeEnum.DIRECT:
      return BookingChannelFilterValue.Direct;
    case ChannelCodeEnum.IBE:
      return BookingChannelFilterValue.Ibe;
    default:
      return BookingChannelFilterValue.Other;
  }
};

const transformReservationBalanceStatusCommonToV2 = (
  balanceStatus: BalanceStatusEnum
): BalanceFilterValue => {
  switch (balanceStatus) {
    case BalanceStatusEnum.ZERO:
      return BalanceFilterValue.Zero;
    case BalanceStatusEnum.POSITIVE:
      return BalanceFilterValue.Positive;
    case BalanceStatusEnum.NEGATIVE:
      return BalanceFilterValue.Negative;
  }
};

const transformReservationRecurringFilterValueCommonToV2 = (
  recurringGuest: RecurringGuestEnum
): RecurringFilterValue => {
  switch (recurringGuest) {
    case RecurringGuestEnum.NEW:
      return RecurringFilterValue.New;
    case RecurringGuestEnum.RECURRING:
      return RecurringFilterValue.Recurring;
  }
};

const transformReservationGuaranteeTypeFilterValueCommonToV2 = (
  guaranteeType: GuaranteeTypeV1
): GuaranteeTypeFilterValue => {
  switch (guaranteeType) {
    case GuaranteeTypeV1.PREPAYMENT:
      return GuaranteeTypeFilterValue.Prepayment;
    case GuaranteeTypeV1.CREDIT_CARD:
      return GuaranteeTypeFilterValue.CreditCard;
    case GuaranteeTypeV1.COMPANY:
      return GuaranteeTypeFilterValue.Company;
  }
};
