import { FC, useRef, useState } from "react";
import { Box, Button, Divider, Grid2, IconButton, Popover, useTheme } from "@mui/material";
import { ParagraphSmallBold, useAuth } from "@likemagic-tech/sv-magic-library";
import { UserAvatar } from "../user-avatar";
import { useUserInfo } from "../../hooks/use-user-info";
import { clearUserInfoSlice } from "../../slices/keycloak.slice";
import { useDispatch, useSelector } from "../../store";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { Logout, MoreVert } from "@mui/icons-material";
import { selectIsOpenSideNavigation } from "../../slices/dashboard-sidebar";
import { useCreateSupportTicket } from "../../hooks/use-create-support-ticket";
import { LanguageSelectorComponent } from "../language-selector/language-selector";
import { useIsMobile } from "../../hooks/use-is-mobile";

interface DashboardMenuPopoverProps {
  showName?: boolean;
  showMoreButton?: boolean;
}

const DashboardMenuPopover: FC<DashboardMenuPopoverProps> = ({ showName, showMoreButton }) => {
  const { t } = useTranslationWrapper();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const auth = useAuth();
  const { userInfo } = useUserInfo();
  const navigate = useNavigateWithPropertyId();
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { createSupportTicket, supportExists } = useCreateSupportTicket();
  const isMobile = useIsMobile();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      dispatch(clearUserInfoSlice());
      await auth.logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Grid2
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "nowrap"
        }}
      >
        <Grid2
          container
          sx={{
            alignItems: "center",
            gap: theme.spacing(1),
            height: "100%",
            flexWrap: "nowrap"
          }}
        >
          <Box ref={!openSideNavigation ? anchorRef : undefined}>
            <UserAvatar
              onClick={!openSideNavigation ? handleOpen : undefined}
              lettersToDisplay={userInfo?.name?.[0]}
              sx={{
                cursor: openSideNavigation ? "default" : "pointer",
                width: 32,
                height: 32,
                flexWrap: "nowrap"
              }}
            />
          </Box>
          {showName && <ParagraphSmallBold>{userInfo?.given_name}</ParagraphSmallBold>}
        </Grid2>
        {showMoreButton && (
          <IconButton
            disableRipple
            sx={{ p: 0 }}
            ref={openSideNavigation ? anchorRef : undefined}
            onClick={openSideNavigation ? handleOpen : undefined}
          >
            <MoreVert
              sx={{
                width: 16,
                height: 16,
                fontSize: 16
              }}
            />
          </IconButton>
        )}
      </Grid2>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        slotProps={{
          paper: {
            sx: {
              display: "flex",
              flexDirection: "column",
              width: 240,
              p: theme.spacing(1),
              gap: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.spacing(0.5)
            }
          }
        }}
      >
        {supportExists && (
          <>
            <Button
              color="primary"
              fullWidth
              onClick={createSupportTicket}
              variant="ghost"
              sx={{
                justifyContent: "space-between",
                borderRadius: theme.spacing(0.5),
                px: theme.spacing(1.5),
                py: theme.spacing(0.5)
              }}
            >
              {t("labels__create_support_ticket")}
            </Button>
            {!isMobile && (
              <LanguageSelectorComponent
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              />
            )}
            <Divider />
          </>
        )}
        <Button
          color="primary"
          fullWidth
          onClick={handleLogout}
          variant="ghost"
          sx={{
            justifyContent: "space-between",
            borderRadius: theme.spacing(0.5),
            px: theme.spacing(1.5),
            py: theme.spacing(0.5)
          }}
          endIcon={
            <Logout
              sx={{
                width: 16,
                height: 16,
                fontSize: 16
              }}
            />
          }
        >
          {t("labels__logout")}
        </Button>
      </Popover>
    </>
  );
};

export default DashboardMenuPopover;
