import * as React from "react";
import { FC } from "react";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useIsMobile, useIsTouchDevice } from "../hooks/use-is-mobile";

interface InputWithIconProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}

export const InputWithIcon: FC<InputWithIconProps> = ({
  value,
  onChange,
  placeholder,
  disabled
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTouchDevice = useIsTouchDevice();

  return (
    <OutlinedInput
      sx={{ height: "32px", borderRadius: theme.spacing(0.75) }}
      disabled={disabled}
      autoFocus={!isMobile && !isTouchDevice}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  );
};
