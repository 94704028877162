import { SvgIcon } from "@mui/material";

export const ExpandSidebarIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2.66699V13.3337M6.00002 6.66699L7.33335 8.00033L6.00002 9.33366M2.66669 4.00033C2.66669 3.6467 2.80716 3.30756 3.05721 3.05752C3.30726 2.80747 3.6464 2.66699 4.00002 2.66699H12C12.3536 2.66699 12.6928 2.80747 12.9428 3.05752C13.1929 3.30756 13.3334 3.6467 13.3334 4.00033V12.0003C13.3334 12.3539 13.1929 12.6931 12.9428 12.9431C12.6928 13.1932 12.3536 13.3337 12 13.3337H4.00002C3.6464 13.3337 3.30726 13.1932 3.05721 12.9431C2.80716 12.6931 2.66669 12.3539 2.66669 12.0003V4.00033Z"
          stroke="#757575"
        />
      </svg>
    </SvgIcon>
  );
};
