import { Person } from "src/domain/person";
import { UpdatePersonInput } from "../generated/graphql";
import {
  mapPersonGenderToGender,
  transformCommunicationChannel
} from "./transform-registration-card";

export const transformPrimaryGuestToUpdateUserInput = (user: Person): UpdatePersonInput => {
  return {
    pmsId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    email: user.email,
    gender: mapPersonGenderToGender(user.gender),
    identificationDocumentNumber: user.identificationNumber,
    nationality: user.nationalityCountryCode,
    preferredLanguage: user.preferredLanguage,
    preferredCommunicationChannel: transformCommunicationChannel(
      user.preferredCommunicationChannel
    ),
    emergencyEvacuationHelpNeeded: user.emergencyEvacuationHelpNeeded,
    emergencyEvacuationHelpNotes: user.emergencyEvacuationHelpNotes,
    enrolledInLoyaltyProgram: user.enrolledInLoyaltyProgram,
    licensePlate: user.licensePlate,
    address: {
      addressLine1: user.address?.addressLine1 ?? "",
      addressLine2: user.address?.addressLine2 ?? "",
      postalCode: user.address?.postalCode ?? "",
      city: user.address?.city ?? "",
      countryCode: user.address?.countryCode ?? "",
      region: user.address?.region ?? ""
    }
  };
};
