import { GetAdditionalServicesAvailabilityQuery } from "../queries/GetAdditionalServicesAvailability.generated";
import { AdditionalServicesAvailabilityResponse } from "../../domain/additional-services-availability";
import {
  DefaultTargetAudience as DefaultTargetAudienceV2,
  Granularity as GranularityV2,
  Visibility as VisibilityV2
} from "../generated/graphql";
import {
  AdditionalServiceWithPrice,
  DefaultTargetAudience,
  Granularity,
  Service,
  Visibility
} from "../../domain/service";
import { transformFullPrice } from "./transform-utils";

type AdditionalServiceInputNotNullable = Exclude<
  GetAdditionalServicesAvailabilityQuery["BackofficeGetAdditionalServicesAvailability"],
  null
>;
type AdditionalServiceInput = Exclude<AdditionalServiceInputNotNullable, undefined>;
type AdditionalServiceItem = AdditionalServiceInput[number];

export const transformAdditionalServicesAvailability = (
  param: AdditionalServiceItem
): AdditionalServicesAvailabilityResponse => {
  return {
    available: param?.available ?? false,
    serviceId: param?.pmsServiceId ?? "",
    name: param?.name ?? "",
    granularity: mapGranularityV2toCommon(param?.granularity),
    bookableDates:
      param?.bookableDates?.map((item) => ({
        date: item.date,
        count: item.count ?? 0
      })) ?? [],
    canBookMultiple: param?.canBookMultiple ?? false,
    bookedDates:
      param?.bookedDates?.map((item) => ({
        date: item.date,
        count: item.count ?? 0
      })) ?? [],
    maximumHours: param?.maximumHours ?? 0,
    defaultDatePickerSelection: param?.defaultDatePickerSelection,
    code: param?.magicServiceCodeEnum ?? "",
    price: transformFullPrice(param?.basePrice),
    defaultTargetAudience: mapDefaultTargetAudienceV2toCommon(param?.defaultTargetAudience),
    maximum: param?.maximum ?? 0,
    visibilities:
      param?.visibilities?.map((visibility) => mapVisibilityV2toCommon(visibility)) ?? [],
    addOnType: param?.addOnType,
    serviceType: param?.serviceType
  };
};

const mapVisibilityV2toCommon = (visibility?: VisibilityV2 | null): Visibility => {
  switch (visibility) {
    case VisibilityV2.GuestFlow:
      return Visibility.GUEST_FLOW;
    case VisibilityV2.MyStay:
      return Visibility.MY_STAY;
    case VisibilityV2.Backoffice:
      return Visibility.BACKOFFICE;
    default:
      return Visibility.MY_STAY;
  }
};

const mapGranularityV2toCommon = (granularity?: GranularityV2 | null): Granularity => {
  switch (granularity) {
    case GranularityV2.AnyDay:
      return Granularity.ANY_DAY;
    case GranularityV2.AnyDayExceptArrivalDay:
      return Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY;
    case GranularityV2.WholeStay:
      return Granularity.WHOLE_STAY;
    case GranularityV2.WholeStayPayOnce:
      return Granularity.WHOLE_STAY_PAY_ONCE;
    case GranularityV2.ArrivalDay:
      return Granularity.ARRIVAL_DAY;
    case GranularityV2.DepartureDay:
      return Granularity.DEPARTURE_DAY;
    case GranularityV2.EarlyCheckin:
      return Granularity.EARLY_CHECKIN;
    case GranularityV2.LateCheckout:
      return Granularity.LATE_CHECKOUT;
    default:
      return Granularity.UNKNOWN;
  }
};

export const transformAdditionalServiceToService = (
  service: AdditionalServiceWithPrice
): Service => ({
  price: service.price,
  code: service.code,
  name: service.name,
  quantity: service.quantity,
  serviceId: service.serviceId,
  dates: service.dates,
  createdAt: "",
  updatedAt: ""
});

const mapDefaultTargetAudienceV2toCommon = (
  defaultTargetAudience?: DefaultTargetAudienceV2 | null
): DefaultTargetAudience | null => {
  switch (defaultTargetAudience) {
    case DefaultTargetAudienceV2.Child:
      return DefaultTargetAudience.CHILD;
    case DefaultTargetAudienceV2.Adult:
      return DefaultTargetAudience.ADULT;
    case DefaultTargetAudienceV2.All:
      return DefaultTargetAudience.ALL;
    default:
      return null;
  }
};
