import {
  Chip,
  formatDateTime,
  ParagraphSmall,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Theme,
  Tooltip
} from "@mui/material";
import { FC, useState } from "react";
import { DeliveryLocationType, FoodAndBeverageOrder } from "src/domain/reservation-table-dto";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getI18nSelectedLanguage } from "src/utils/language";
import { useProperty } from "src/hooks/use-property";
import { makeStyles } from "tss-react/mui";

interface FoodAndBeverageOrderItemProps {
  order?: FoodAndBeverageOrder | null;
}

const useStyles = makeStyles()((theme: Theme) => ({
  accordion: {
    ":first-of-type": {
      borderTopLeftRadius: theme.spacing(0),
      borderTopRightRadius: theme.spacing(0)
    }
  }
}));

export const FoodAndBeverageOrderItem: FC<FoodAndBeverageOrderItemProps> = ({ order }) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const { classes } = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      key={order?.foodAndBeverageId}
      sx={{ width: "100%", paddingInline: 3 }}
      className={classes.accordion}
      disableGutters
    >
      <AccordionSummary
        sx={{ flexDirection: "row-reverse", p: 0 }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <Grid2 container direction="row" width={"100%"} alignItems="center" spacing={1}>
          <Grid2 size={{ xs: 3 }} sx={{ display: "flex", gap: 1 }}>
            <ExpandMoreIcon
              color="primary"
              style={{ transform: !isExpanded ? "unset" : "rotate(180deg)" }}
            />
            <Tooltip title={!order?.externalOrderNumber ? "" : order?.foodAndBeverageId}>
              <span>
                <ParagraphSmallBold>{`${t("labels__food_and_beverage_orders_order")}: ${
                  order?.externalOrderNumber ? order?.externalOrderNumber : order?.foodAndBeverageId
                }`}</ParagraphSmallBold>
              </span>
            </Tooltip>
          </Grid2>
          <Grid2 size={{ xs: 2 }} />
          <Grid2 size={{ xs: 2 }}>
            <ParagraphSmall>
              {formatDateTime(order?.createdAt, language, selectedProperty.details.timeZone)}
            </ParagraphSmall>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <ParagraphSmall>
              {formatDateTime(order?.updatedAt, language, selectedProperty.details.timeZone)}
            </ParagraphSmall>
          </Grid2>
          <Grid2 size={{ xs: 1 }}>
            <ParagraphSmall>
              {order?.deliveryLocationType === DeliveryLocationType.InRoom
                ? t("labels__food_and_beverage_order_item_location_type_IN_ROOM")
                : `${t(
                    "labels__food_and_beverage_order_item_location_type_TABLE"
                  )} ${order?.deliveryLocation}`}
            </ParagraphSmall>
          </Grid2>
          <Grid2 size={{ xs: 2 }} textAlign="center">
            <Chip
              size="small"
              variant="outlined"
              color={"info"}
              label={t(`labels__food_and_beverage_order_item_status_${order?.status}`)}
            />
          </Grid2>
        </Grid2>
      </AccordionSummary>
      <AccordionDetails>
        {order?.orderItems?.map((item) => (
          <Grid2
            container
            direction="row"
            width={"100%"}
            sx={{ px: 2 }}
            key={item.foodAndBeverageId}
          >
            <Grid2 size={{ xs: 3 }}>
              <ParagraphSmall>{item.name}</ParagraphSmall>
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <ParagraphSmall textAlign="center">{item.quantity}</ParagraphSmall>
            </Grid2>
          </Grid2>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
