import { EmptyState, Heading4, ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { Box, Divider, Grid2, Paper } from "@mui/material";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { FoodAndBeverageOrderItem } from "./food-and-beverage-order-item";
import React from "react";

interface ReservationDetailsFoodAndBeverageOrdersProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsFoodAndBeverageOrders: FC<
  ReservationDetailsFoodAndBeverageOrdersProps
> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  return (
    <Box paddingTop={2}>
      <Paper elevation={0}>
        <Grid2 container direction="column">
          <Grid2 padding={3}>
            <Heading4>{t("labels__food_and_beverage_orders_details_title")}</Heading4>
          </Grid2>
          {reservation?.foodAndBeverageOrders.length > 0 ? (
            <>
              <Grid2
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ px: 3 }}
                spacing={1}
              >
                <Grid2 size={{ xs: 3 }}>
                  <ParagraphSmallBold>
                    {t("labels__food_and_beverage_orders_order")}
                  </ParagraphSmallBold>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                  <ParagraphSmallBold textAlign="center">
                    {t("labels__food_and_beverage_orders_quantity")}
                  </ParagraphSmallBold>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                  <ParagraphSmallBold>{t("labels__created_at")}</ParagraphSmallBold>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                  <ParagraphSmallBold>{t("labels__updated_at")}</ParagraphSmallBold>
                </Grid2>
                <Grid2 size={{ xs: 1 }}>
                  <ParagraphSmallBold>
                    {t("labels__food_and_beverage_orders_location")}
                  </ParagraphSmallBold>
                </Grid2>
                <Grid2 size={{ xs: 2 }} textAlign="center">
                  <ParagraphSmallBold>
                    {t("labels__food_and_beverage_orders_status")}
                  </ParagraphSmallBold>
                </Grid2>
              </Grid2>
              <Divider />
              <Grid2 container>
                {reservation?.foodAndBeverageOrders.map((order) => (
                  <FoodAndBeverageOrderItem order={order} key={order?.foodAndBeverageId} />
                ))}
              </Grid2>
            </>
          ) : (
            <EmptyState title={t("labels__food_and_beverages_orders_empty")} />
          )}
        </Grid2>
      </Paper>
    </Box>
  );
};
