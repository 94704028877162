import Paper from "@mui/material/Paper";
import { Grid2, useTheme } from "@mui/material";
import { EmptyState, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import { FC } from "react";
import { grey } from "@mui/material/colors";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Address } from "src/graphql/generated/graphql";
import { regionNames } from "../../../reservations/reservation-details/reservation-details-tabs/guest-insights/reservation-details-guest-insight-utils";

interface ProfileDrawerCardProps {
  addressInformation?: Address | null;
}
const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: grey[600]
    }
  })
);

export const ProfileDrawerAddressInformationCard: FC<ProfileDrawerCardProps> = ({
  addressInformation
}) => {
  const classes = useStyles();
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  return (
    <Grid2 size={{ xs: 12 }}>
      <Paper elevation={0}>
        <Grid2
          container
          sx={{
            gap: 3,
            p: 3,
            flexDirection: "column"
          }}
        >
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Grid2>
              <Heading2>{t(`labels__profiles_address_information`)}</Heading2>
            </Grid2>
            {/* Let's add the icon whenever we can edit */}
            {/* <Grid2 >
              <IconButton>
                <Edit />
              </IconButton>
            </Grid2> */}
          </Grid2>
          {addressInformation ? (
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph className={classes.label} sx={{ color: theme.palette.grey[600] }}>
                      {t(`labels__profiles_street`)}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{addressInformation?.addressLine1}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph className={classes.label} sx={{ color: theme.palette.grey[600] }}>
                      {t(`labels__profiles_city`)}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{addressInformation?.city}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph className={classes.label} sx={{ color: theme.palette.grey[600] }}>
                      {t(`labels__profiles_state`)}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{addressInformation?.region}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph className={classes.label} sx={{ color: theme.palette.grey[600] }}>
                      {t(`labels__profiles_postcode`)}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    <Paragraph>{addressInformation?.postalCode}</Paragraph>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Grid2 container direction="row">
                  <Grid2 size={{ xs: 4 }}>
                    <Paragraph className={classes.label} sx={{ color: theme.palette.grey[600] }}>
                      {t(`labels__profiles_country`)}
                    </Paragraph>
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    {addressInformation?.countryCode && (
                      <Paragraph>{regionNames.of(addressInformation?.countryCode)}</Paragraph>
                    )}
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          ) : (
            <EmptyState title={t("labels__profile_no_address_information")} />
          )}
        </Grid2>
      </Paper>
    </Grid2>
  );
};
