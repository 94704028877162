import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customErrors, prepareHeaders } from "../utils/graphql.api";

export const TENANTCONFIG_BASE_URL = "/api/tenant-management-service/graphql";
export const TenantConfigGraphqlClient = new GraphQLClient(TENANTCONFIG_BASE_URL);

export const tenantconfigApi = createApi({
  reducerPath: "graphql-tenantconfig",
  baseQuery: graphqlRequestBaseQuery({
    client: TenantConfigGraphqlClient,
    prepareHeaders,
    customErrors
  }),
  tagTypes: ["ServiceConfigs"],
  endpoints: () => ({})
});
