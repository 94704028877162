import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ReleaseNotesState {
  totalReleaseNotes: number;
  notRead: number;
}

const initialState: ReleaseNotesState = {
  totalReleaseNotes: 0,
  notRead: 0
};

export const releaseNotesSlice = createSlice({
  name: "releaseNotes",
  initialState,
  reducers: {
    updateReleaseNotes: (state, action: PayloadAction<number>) => {
      const totalNewReleaseNotes = action.payload;
      if (totalNewReleaseNotes > state.totalReleaseNotes) {
        state.notRead = totalNewReleaseNotes - state.totalReleaseNotes;
      }
      state.totalReleaseNotes = totalNewReleaseNotes;
    },
    markAsRead: (state) => {
      state.notRead = 0;
    }
  }
});

export const { updateReleaseNotes, markAsRead } = releaseNotesSlice.actions;

export const selectReleaseNotesCount = (state: RootState) => state.releaseNotes.notRead;
