import React, { FC } from "react";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { Campaign } from "@mui/icons-material";
import { SidebarItem } from "../../components/dashboard/sidebar/sidebar-item";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { markAsRead, selectReleaseNotesCount } from "../../slices/release-notes.slice";
import { useDispatch, useSelector } from "react-redux";

interface ReleaseNoteButtonProps {
  setOpenDrawer: (open: boolean) => void;
}

export const ReleaseNotesButton: FC<ReleaseNoteButtonProps> = ({ setOpenDrawer }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const newReleaseNotesCounter = useSelector(selectReleaseNotesCount);
  const isMobile = useIsMobile();

  const handleIconClick = () => {
    setOpenDrawer(true);
    dispatch(markAsRead());
  };

  return (
    <SidebarItem
      title={t("labels__whats_new")}
      icon={<Campaign fontSize="small" />}
      isCollapsable={false}
      badgeNumber={newReleaseNotesCounter}
      onClick={handleIconClick}
      iconOnly={isMobile}
    />
  );
};
