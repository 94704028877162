import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customErrors, prepareHeaders } from "../utils/graphql.api";

export const TASKS_BASE_URL = "/api/task-management-service/graphql";
export const TasksGraphqlClient = new GraphQLClient(TASKS_BASE_URL);

export const tasksApi = createApi({
  reducerPath: "graphql-tasks",
  baseQuery: graphqlRequestBaseQuery({
    client: TasksGraphqlClient,
    prepareHeaders,
    customErrors
  }),
  tagTypes: [
    "HousekeepingCards",
    "HousekeepingTabCounts",
    "HousekeepingTabCounts",
    "LeftoverTasks",
    "AdditionalTasks",
    "Tasks",
    "Tasks-Related-To-Reservations"
  ],
  endpoints: () => ({})
});
