// Declare the global interface for the custom element
import { FC, useEffect, useRef } from "react";

// Define the props for our component
interface SevenRoomsWidgetProps {
  guestIdentityId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  locale: string;
  hotelReservationArrivalDate: string;
  hotelReservationDepartureDate: string;
  amountOfPeople: number;
  mode: string;
  toggle: boolean;
  reservationId: string;
  restaurantReservationId?: string;
  apiKey: string;
  foodAndBeveragePropertyId: string;
  onClose: (toggle: boolean) => void;
}

type SevenRoomsWidgetPropsWebComponent = {
  "guest-identity-id": string;
  "first-name": string;
  "last-name": string;
  email: string;
  phone: string;
  locale: string;
  "hotel-reservation-arrival-date": string;
  "hotel-reservation-departure-date": string;
  "seven-rooms-reservation-id"?: string;
  "amount-of-people": number;
  mode: string;
  toggle: boolean;
  "hotel-reservation-id": string;
};
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "sevenrooms-widget": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SevenRoomsWidgetPropsWebComponent,
        HTMLElement
      >;
    }
  }
}

export const SevenroomsWidget: FC<SevenRoomsWidgetProps> = (props) => {
  const widgetRef = useRef<HTMLElement>(null);
  useEffect(() => {
    setTimeout(() => {
      const widgetRefCurrent = widgetRef.current;

      if (!widgetRefCurrent) {
        return;
      }

      // @ts-ignore
      widgetRefCurrent.onClose = (toggle: boolean) => {
        props?.onClose(toggle);
      };
    }, 1000);
  }, [props]);

  return (
    <sevenrooms-widget
      id="myWidget"
      ref={widgetRef}
      hotel-reservation-arrival-date={props.hotelReservationArrivalDate}
      hotel-reservation-departure-date={props.hotelReservationDepartureDate}
      guest-identity-id={props.guestIdentityId}
      first-name={props.firstName}
      last-name={props.lastName}
      email={props.email}
      phone={props.phone}
      locale={props.locale}
      amount-of-people={props.amountOfPeople}
      mode={props.mode}
      hotel-reservation-id={props.reservationId}
      toggle={props.toggle}
      seven-rooms-reservation-id={props.restaurantReservationId}
      api-key={props.apiKey}
      venue-id={props.foodAndBeveragePropertyId}
    />
  );
};
