import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import * as React from "react";
import { FC, SyntheticEvent, useMemo } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { ReservationTabs, TabsList } from "../reservation-details/reservation-details-constants";
import { ReservationDetailsOverview } from "./overview/reservation-details-overview";
import ReservationDetailsComments from "./notes/reservation-details-comments";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { ReservationDetailsKeys } from "./keys/reservation-details-keys";
import ReservationDetailsServices from "./services/reservation-details-services";
import { ReservationDetailsNotifications } from "./notifications/reservation-details-notifications";
import { grey } from "@mui/material/colors";
import { ChipStatus } from "../../../../components/chip/chip-status";
import { Notification } from "../../../../components/notification";
import { buttonVariantMap } from "../../../../components/chip/chip-button";
import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { Task } from "../../../tasks/domain/task";
import { ReservationDetailsRestaurants } from "./restaurants/reservation-details-restaurants";
import { UpsellUnitGroupsTab } from "./upsell/upsell-unit-groups-tab";
import { useFeatureFlags } from "../../../../hooks/use-configuration";
import { ReservationDetailsFolios } from "./folios/reservation-details-folios";
import { useReservationTab } from "./use-reservation-tab";
import { ReservationDetailsGuestInsight } from "./guest-insights/reservation-details-guest-insight";
import { ReservationDetailsFoodAndBeverageOrders } from "./food-and-beverage-orders/reservation-details-food-and-beverage-orders";

interface ReservationDetailsTabsProps {
  reservation: ReservationDetailsDTO;
  tasks: Task[];
}

export const ReservationDetailsTabs: FC<ReservationDetailsTabsProps> = ({ reservation, tasks }) => {
  const { t } = useTranslationWrapper();
  const { selectedTab, setSelectedTab } = useReservationTab();
  const features = useFeatureFlags();
  const doorConfig = useDoorProviderConfig(reservation.propertyId);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const changeTabMoreInfo = (tab: string) => {
    setSelectedTab(tab as ReservationTabs);
  };

  const isChangeCommentAllowed = reservation?.allowedActions?.includes(
    AllowedActionEnum.CHANGE_RESERVATION_COMMENT
  );

  const tabsBasedOnFeatures = useMemo(
    () =>
      TabsList.filter((item) =>
        item === ReservationTabs.BILLING ? features?.billingTabEnabled : true
      )
        .filter((item) =>
          item === ReservationTabs.UPSELL_UNIT_GROUP ? features?.upsellUnitGroupEnabled : true
        )
        .filter((item) =>
          item === ReservationTabs.RESTAURANT ? features?.restaurantReservationEnabled : true
        )
        .filter((item) =>
          item === ReservationTabs.FOOD_AND_BEVERAGE_ORDERS
            ? features?.foodAndBeverageEnabled
            : true
        ),
    [
      features?.billingTabEnabled,
      features?.upsellUnitGroupEnabled,
      features?.restaurantReservationEnabled,
      features?.foodAndBeverageEnabled
    ]
  );

  return (
    <>
      {reservation?.reservationConditionReasons && (
        <Box>
          {Object.entries(reservation.reservationConditionReasons).map(
            ([reasonKey, value]) =>
              value === ChipStatus.CRITICAL && (
                <Box
                  key={`key-${reasonKey}-${value}`}
                  sx={{
                    my: 1
                  }}
                >
                  <Notification
                    type={buttonVariantMap[reservation.reservationConditionReasons[reasonKey]]}
                    title={t(`labels__reservation_condition_${reasonKey}`)}
                  />
                </Box>
              )
          )}
        </Box>
      )}
      <Box sx={{ width: "100%" }}>
        <TabContext value={selectedTab ?? ReservationTabs.OVERVIEW}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              bgcolor: "background.default",
              top: 0,
              zIndex: 100
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Reservation Details tabs"
              variant="scrollable"
            >
              {tabsBasedOnFeatures.map((item) => (
                <Tab
                  label={t(`labels__tab_title_${item}`)}
                  value={item}
                  key={item}
                  sx={{
                    textTransform: "none",
                    color: grey[600],
                    fontWeight: "bold"
                  }}
                />
              ))}
            </TabList>
          </Box>
        </TabContext>
        {(selectedTab === ReservationTabs.OVERVIEW || !selectedTab) && (
          <ReservationDetailsOverview
            reservation={reservation}
            tasks={tasks}
            changeTabMoreInfo={changeTabMoreInfo}
          />
        )}
        {selectedTab === ReservationTabs.COMMENTS && (
          <ReservationDetailsComments
            reservation={reservation}
            isChangeCommentAllowed={isChangeCommentAllowed}
          />
        )}
        {selectedTab === ReservationTabs.INSIGHTS && (
          <ReservationDetailsGuestInsight reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.KEYS &&
          doorConfig?.doorProvider !== DoorProvider.OFFLINE_KEY && (
            <ReservationDetailsKeys reservation={reservation} />
          )}
        {selectedTab === ReservationTabs.SERVICES && (
          <ReservationDetailsServices reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.NOTIFICATIONS && (
          <ReservationDetailsNotifications reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.BILLING && (
          <ReservationDetailsFolios reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.RESTAURANT && (
          <ReservationDetailsRestaurants reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.UPSELL_UNIT_GROUP && (
          <UpsellUnitGroupsTab reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.FOOD_AND_BEVERAGE_ORDERS && (
          <ReservationDetailsFoodAndBeverageOrders reservation={reservation} />
        )}
      </Box>
    </>
  );
};
