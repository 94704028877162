import { AppBar, AppBarProps, Grid2, styled, Toolbar, useTheme } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { selectIsOpenSideNavigation, setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useDispatch, useSelector } from "src/store";
import CloseIcon from "@mui/icons-material/Close";
import DashboardMenuPopover from "./dashboard-menu-popover";
import { ReleaseNotes } from "../../features/release-notes/release-notes";
import { LanguageSelectorComponent } from "../language-selector/language-selector";

interface DashboardNavbarProps extends AppBarProps {}

export const minimumNavbarHeight = 32;

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none"
  }),
  zIndex: theme.zIndex.drawer + 100,
  backgroundColor: `${theme.palette.background.default} !important`,
  paddingTop: 12,
  paddingBottom: 12,
  borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

const DashboardNavbar: FC<PropsWithChildren<DashboardNavbarProps>> = (props) => {
  const { ...other } = props;
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const dispatch = useDispatch();
  const theme = useTheme();

  const setOpenSideNavigation = (openSidebar: boolean) => {
    dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
  };
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          minHeight: minimumNavbarHeight,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.between("sm", "md")]: {
            minHeight: minimumNavbarHeight
          }
        }}
      >
        {openSideNavigation ? (
          <CloseIcon
            sx={{ color: theme.palette.grey[600], cursor: "pointer" }}
            onClick={() => setOpenSideNavigation(false)}
          />
        ) : (
          <MenuIcon
            sx={{ color: theme.palette.grey[600], cursor: "pointer" }}
            onClick={() => setOpenSideNavigation(true)}
          />
        )}
        <Grid2
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1),
            alignItems: "center"
          }}
        >
          <LanguageSelectorComponent
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          />
          <ReleaseNotes />
          <DashboardMenuPopover />
        </Grid2>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
